import React, { useRef, useEffect, useMemo, useCallback, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import formatStringPattern from "common/format-string-pattern";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppPolicyModal from "components/pages/insurance-management/app-policy-modal";
import AppTable, { indexing, AppTableCell, AppTableEditIcon } from "components/app-table";
import AppExportReportModal, { TYPES } from "components/pages/insurance-management/app-export-report-modal";
import exportIcon from "assets/images/export-icon.svg";
import previewIcon from "assets/images/preview-icon.svg";

const TAB = "EMPLOYEE_BENEFIT";

const AppPoliciesEmployeeBenefitTable = (props) => {
	const tableRef = useRef();
	const policyRef = useRef();
	const searchInputRef = useRef();
	const exportReportModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
    const propTab = useMemo(() => props.tab, [props.tab]);
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || defaultSortBy,
		customerName: searchParams.get("customerName") || "",
		identity: searchParams.get("identity") || "",
		policyNo: searchParams.get("policyNo") || "",
		businessRegistrationNo: searchParams.get("businessRegistrationNo") || "",
        tab: propTab,
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const initialValues = useMemo(() => ({ identity: "", companyName: "", customerName: "", businessRegistrationNo: "", status: "" }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.customerName && !paramsRef.current.identity && !paramsRef.current.businessRegistrationNo && !paramsRef.current.policyNo, [data.status]);
	const isSearchState = useCallback(() => {
		const { tab, page, sort, ...res } = paramsRef.current;
		return Object.values(res).some((o) => o);
	}, []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { identity: params.identity, customerName: params.customerName, businessRegistrationNo: params.businessRegistrationNo, policyNo: params.policyNo };

		return values;
	}, [searchParams]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };
			
			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.policies.ebList(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable
			}));
		}
	}, []);

	const onHandleEdit = useCallback((obj) => {
		policyRef.current.onHandleShow(obj);
	}, []);

	const onHandleExportReport = useCallback(() => {
		exportReportModalRef.current.onHandleShow();
	}, []);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		if (paramsRef.current.identity) paramsRef.current.identity = paramsRef.current.identity.replaceAll(/[^a-zA-Z0-9]/g, "");

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		switch (id) {
			case "planName":
				paramsRef.current.sort = order ? "name," + order : "";
				break;
			case "providerName":
				paramsRef.current.sort = order ? "x.name," + order : "";
				break;
			default:
				paramsRef.current.sort = order ? id + "," + order : defaultSortBy;
				break;
		}

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={previewIcon} onClick={() => onHandleEdit(row.original)} />; 
	}, [onHandleEdit]);

	const PolicyEffectiveDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.policyEffectiveDate))} />;
	}, []);

	const PolicyExpiryDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.policyExpiryDate))} />;
	}, []);

	//prettier-ignore
	const CustomerIdCell = useCallback(({ row }) => {
		const isPassport = row.original.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT;
		const value = isPassport ? row.original.identity : formatStringPattern(row.original.identity, COMMON.MASKING.NRIC);
		
		return <AppTableCell left value={value} />;
	}, []);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Policy No.",
			accessor: "policyNo",
			disableSortBy: false,
		},
		{
			Header: "Provider",
			accessor: "providerName",
			disableSortBy: false,
		},
		{
			Header: "Plan Name",
			accessor: "planName",
			disableSortBy: false,
		},
		{
			Header: "Customer ID",
			accessor: "identity",
			disableSortBy: true,
            Cell: CustomerIdCell
		},
		{
			Header: "Customer Name",
			accessor: "customerName",
			disableSortBy: true,
		},
		{
			Header: "Business Reg. No.",
			accessor: "businessRegNo",
			disableSortBy: true,
		},
		{
			Header: "Policy Effective Date",
			accessor: "policyEffectiveDate",
			disableSortBy: false,
            Cell: PolicyEffectiveDateCell
		},
		{
			Header: "Policy Expiry Date",
			accessor: "policyExpiryDate",
			disableSortBy: false,
            Cell: PolicyExpiryDateCell
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, PolicyEffectiveDateCell, PolicyExpiryDateCell, CustomerIdCell, StatusCell]);

	useEffect(() => {
		if (propTab === TAB) onHandleGetList();
	}, [propTab, onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.POLICIES.EB_LIST);
		};
	}, [cancelRequest]);

	return (
		<Fragment>
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

			{!isEmptyState && (
				<div className="policies">
					{/* prettier-ignore */}
					<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttonLabel="Export" buttonIcon={exportIcon} onButtonClick={onHandleExportReport}>
					<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
						<div className="advance-form">
							<div className="advance-form__inputs">
								<AppInput type="text" name="policyNo" label="Policy No." placeholder="Enter Policy No." value={formik.values.policyNo} onChange={formik.handleChange} />
								
								<AppInput type="text" name="identity" label="Customer ID" placeholder="Enter Customer ID" value={formik.values.identity} onChange={formik.handleChange} />
								
								<AppInput type="text" name="customerName" label="Customer Name" placeholder="Enter Customer Name" value={formik.values.customerName} onChange={formik.handleChange} />
								
								<AppInput type="text" name="businessRegistrationNo" label="Business Reg. No." placeholder="Enter Business Reg. No." value={formik.values.businessRegistrationNo} onChange={formik.handleChange} />
							</div>

							<div className="advance-form__button-container">
								<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
								<AppButton type="submit" label="Search" />
							</div>
						</div>
					</form>
				</AppSearchInput>

					{isSearchState() && (
						<div className="policies__results">
							<p className="policies__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
				</div>
			)}

			<AppPolicyModal ref={policyRef} />

			<AppExportReportModal ref={exportReportModalRef} type={TYPES.POLICIES} />
		</Fragment>
	);
};

export default AppPoliciesEmployeeBenefitTable;
