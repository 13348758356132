import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import { formatDatePattern, CALENDAR_FORMAT } from "common/calendar";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";
import { ReactComponent as LoadingLogo } from "assets/images/loading-logo.svg";

export const AppEndorsementModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (id) => {
		let response = null;

		try {
            switch(props.tab) {
                case "DEBIT_NOTE":
                    response = await api.get.auditLog.endorsementDebitNote(id);
                    break;
                case "CREDIT_NOTE":
                    response = await api.get.auditLog.endorsementCreditNote(id);
                    break;
                default: 
                    break;
            }
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) setData(response);
	}, [props?.tab]);

	//prettier-ignore
	const onHandleShow = useCallback((id) => {
        setVisible(true);
		if (id) onHandleGetDetails(id);
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-endorsement-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="endorsement-modal" aria-describedby="endorsement-modal">
			<div className="endorsement-modal">
				<div className="endorsement-modal__main">
					<button className="endorsement-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="endorsement-modal__header">
						<p className="endorsement-modal__title">{data.trxNo || data.refNo}</p>
						<p className="endorsement-modal__description">Application Submission Date: {formatDatePattern(data.createdDate)}</p>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="endorsement-modal__text">Total Amount</p>
							<p className="endorsement-modal__amount">RM {formatCurrency(data.totalAmount)}</p>
						</div>
						<div className="info__box">
							<p className="endorsement-modal__text">Status</p>
							<div className="endorsement-modal__title">
								<AppStatus status={data?.status || "-"} />
							</div>
						</div>
					</div>

					<div className="endorsement-modal__body">
						<div className="endorsement-modal__container">
							<div className="endorsement-modal__info">
								<p className="endorsement-modal__label">Payment Ref. No.</p>
								<p className="endorsement-modal__value">{data.paymentNo || "-"}</p>
							</div>

							<div className="endorsement-modal__info">
								<p className="endorsement-modal__label">Policy No.</p>
								<p className="endorsement-modal__value">{data.policyNo}</p>
							</div>

							<div className="endorsement-modal__info">
								<p className="endorsement-modal__label">Company Name</p>
								<p className="endorsement-modal__value">{data.companyName}</p>
							</div>
						</div>
					</div>

					<div className="endorsement-modal__divider" />

					<div className="endorsement-modal__body endorsement-modal__body--invoice">
						<p className="endorsement-modal__sub-title">Invoice</p>

						<div className="endorsement-modal__scrollable">
							{!data.invoices?.length && (
								<div className="endorsement-modal__loading">
									<LoadingLogo />
								</div>
							)}

							{data.invoices?.length && (
								<ul className="endorsement-modal__list">
									{data.invoices.map((o, i) => {
										const dateFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_SHORT_FORMAT;
										const displayDate = o.invoiceDate !== data.items?.[i - 1]?.invoiceDate || !data.items?.[i - 1]?.invoiceDate;

										return (
											<li className="endorsement-modal__item" key={i}>
												{displayDate && <p className="endorsement-modal__date">{formatDatePattern(o.invoiceDate, dateFormat)}</p>}
												<div className="endorsement-modal__invoice">
													<p className="endorsement-modal__text">• {o.invoiceNo}</p>
													<p className="endorsement-modal__text">{formatCurrency(o.invoiceAmount)}</p>
												</div>
											</li>
										);
									})}
								</ul>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppEndorsementModal));

AppEndorsementModal.propTypes = {
	ref: PropTypes.object,
};
