import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Switch from "@mui/material/Switch";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppTable from "components/app-table";
import AppButton from "components/app-button";

const PageUserRolePrivileges = (props) => {
	let { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isCreate = useMemo(() => id === COMMON.APP_STATUS.NEW, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const [data, setData] = useState({ page: 1, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const updateAccessible = restrictedActions(ROLES.ROLE_PRIVILEGES, isCreate ? ROLES.CREATE_ROLE_PRIVILEGES : ROLES.UPDATE_ROLE_PRIVILEGES);
	const initialValues = useMemo(() => ({ role: "", description: "", permissions: [] }), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			role: yup.string().required(ERRORS.REQUIRED),
			description: yup.string().required(ERRORS.REQUIRED),
			permissions: yup.array().min(1, ERRORS.MININUM_ITEM).required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const memoSetValues = useMemo(() => formik.setValues, [formik.setValues]);
	const memoSetFieldValues = useMemo(() => formik.setFieldValue, [formik.setFieldValue]);
	const permissionIds = useMemo(() => formik.values.permissions, [formik.values.permissions]);
	const title = useMemo(() => (isCreate ? "New Role" : formik.values.role), [isCreate, formik.values.role]);
	const isDisabled = useMemo(() => !updateAccessible || formik.isSubmitting, [updateAccessible, formik.isSubmitting]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;
		try {
			response = await api.get.rolesPrivileges.permissionList();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({ ...prev, items: [...response] }));
		}
	}, []);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				roleNameEn: values.role,
				descriptionEn: values.description,
				permissionIds: values.permissions,
			};
			if (isCreate) {
				response = await api.post.rolesPrivileges.create(payload);
			} else {
				payload.id = id;
				response = await api.post.rolesPrivileges.update(payload);
			}
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(-1);
			dispatch(promptAlertMessage({ message: isCreate ? `${values.role} has been created successfull` : `${values.role} has been updated successfull` }));
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	//prettier-ignore
	const onHandlePermissionsChange = useCallback((obj) => {
		let values = [...formik.values.permissions];

		const permissionId = obj.id;
		const permissionName = obj.name;
		const parentPermissions = data.items.find(o => o.id === obj.parentId);

		const duplicated = values.findIndex((o) => o === permissionId) > -1;

		if (duplicated) {
			const isPermissionView = permissionName.includes(":view");

			if(isPermissionView) {
				values = values.filter((a) => parentPermissions.childs.findIndex(b => b.id === a) === -1);
			}
			else {
				values = values.filter((o) => o !== permissionId);
			}
		} else {
			const actions = [":create", ":update", ":delete", ":download", ":export"];
			const isPermissionUpdate = actions.some(o => permissionName.includes(o));

			if(isPermissionUpdate) {
				const viewPermissionId = parentPermissions.childs.find(a => a.name.includes(":view")).id;
				const found = values.some(a => a === viewPermissionId);
				
				if(!found) values.push(viewPermissionId);
				
				values.push(permissionId);
			}
			else {
				values.push(permissionId);
			}
		}
		memoSetFieldValues("permissions", values);
	}, [data, formik, memoSetFieldValues]);

	//prettier-ignore
	const PermissionSwitchCell = useCallback(({ row }) => {
		return (
			<ul className="table__permissions">
				{row.original.childs?.map((o) => {
					const isChecked = permissionIds.findIndex((j) => j === o.id) > -1;

					return (
						<li className="table__permission" key={o.id}>
							<Switch defaultChecked={isChecked} disabled={isDisabled} onChange={() => onHandlePermissionsChange(o)} />
							<p className="table__permission-label">{o.title}</p>
						</li>
					);
				})}
			</ul>
		);
	}, [isDisabled, permissionIds, onHandlePermissionsChange]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "",
			accessor: "title",
			disableSortBy: true,
			Cell: (props) => <div className="table__title--values">{props.row.original.title}</div>,
		},
		{
			Header: "",
			accessor: "childs",
			disableSortBy: true,
			Cell: PermissionSwitchCell,
		},
	], [PermissionSwitchCell]);

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.rolesPrivileges.role(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				memoSetValues({
					role: response.roleNameEn,
					description: response.descriptionEn,
					permissions: response.permissions.map((o) => o.id),
				});
			}
		};

		if (!isCreate) onHandleGetDetails();
	}, [id, isCreate, memoSetValues]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.ROLE);
			cancelRequest(COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.PERMISSION_LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-user-role-privileges">
			<div className="role-privileges">
				<h1 className="role-privileges__name">{title}</h1>

				<form className="role-privileges__form" onSubmit={formik.handleSubmit}>
					<div className="role-privileges__container">
						<div className="role-privileges__box">
							<div className="role-privileges__wrapper">
								<div className="role-privileges__box-body">
									{/* prettier-ignore */}
									<AppInput required type="text" name="role" label="Role Name" placeholder="Please enter Role Name" value={formik.values.role} error={formik.errors.role} touched={formik.touched.role} disabled={isDisabled} onChange={formik.handleChange} />
								</div>
								<div className="role-privileges__box-body">
									{/* prettier-ignore */}
									<AppInput required multiline maxLength={250} type="text" name="description" label="Description" placeholder="Please enter a description of the role" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} disabled={isDisabled} onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="role-privileges__container">
						<div className="role-privileges__box">
							<div className="role-privileges__box-header">
								<p className="role-privileges__title">Permissions</p>
							</div>

							<div className="role-privileges__wrapper role-privileges__wrapper--border">
								<AppTable columns={columns} pages={data} hideColumnSelect />
							</div>

							{formik.touched.permissions && formik.errors.permissions && <div className="role-privileges__footer">{formik.errors.permissions}</div>}
						</div>
					</div>

					<div className="role-privileges__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" label={submitLabel} disabled={isDisabled} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageUserRolePrivileges;
