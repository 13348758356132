import React, { memo, forwardRef, useImperativeHandle, useState, useCallback, useEffect, useMemo } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";

import api from "services/api";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";
import AppScrollableTabs from "components/app-scrollable-tabs";
import { ReactComponent as UploadIcon } from "assets/images/upload-icon.svg";
import classNames from "common/class-names";

const TAB = {
	POLICY_INFO: "POLICY_INFO",
	PAYMENT_HISTORY: "PAYMENT_HISTORY",
};

const TABS = [
	{ label: "Policy Info", value: TAB.POLICY_INFO },
	{ label: "Payment History", value: TAB.PAYMENT_HISTORY },
];

export const AppPolicyMotorModal = (props, ref) => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);
	const [tab, setTab] = useState(TAB.POLICY_INFO);

	const downloadPolicyClassName = useMemo(() => classNames({ "policy-motor-modal__download-button": true, "policy-motor-modal__download-button--disabled": !data?.policyDocument }), [data]);

	const productInfo = useMemo(() => {
		const info = [
			{ label: "Policy No.", value: data?.policyNo || "-" },
			{ label: "Sum Insured", value: data?.sumInsured || "-" },
			{ label: "Effective Date", value: data?.effectiveDate ? formatDatePattern(data?.effectiveDate) : "-" },
			{ label: "Next Due Date", value: data?.expiryDate ? formatDatePattern(data?.expiryDate) : "-" },
			{ label: "Insurance Provider", value: data?.providerName || "-" },
		];

		return info;
	}, [data]);

	const policyOwnerDetails = useMemo(() => {
		const info = [
			{ label: "Policy Owner", value: data?.customerName || "-" },
			{ label: "NRIC / Passport", value: data?.nric || data?.passport || "-" },
		];

		return info;
	}, [data]);

	const vehicleInfo = useMemo(() => {
		const info = [
			{ label: "Registration No.", value: data?.vehicleRegNo || "-" },
			{ label: "Model", value: data?.vehicleModel || "-" },
			{ label: "Make Year", value: data?.vehicleMakeYear || "-" },
			{ label: "Engine Displacement (CC)", value: data?.vehicleCapacity || "-" },
			{ label: "Chasis No.", value: data?.vehicleChassisNo || "-" },
			{ label: "Engine No.", value: data?.vehicleEngineNo || "-" },
			{ label: "Seat", value: data?.vehicleSeat || "-" },
			{ label: "Transmission", value: data?.vehicleTransmission || "-" },
			{ label: "Build Type", value: data?.vehicleBuildType || "-" },
			{ label: "ISM Market Value", value: data?.vehicleIsmMarketValue ? `RM ${formatCurrency(data?.vehicleIsmMarketValue)}` : "-" },
		];

		return info;
	}, [data]);

	const onHandleShow = useCallback((id) => {
		setData((prev) => ({ ...prev, id }));
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setData({});
		setTab(TAB.POLICY_INFO);
		setVisible(false);
	}, []);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;
			try {
				response = await api.get.motor.policy.motor(data.id);
			} catch (error) {
				serveRequestErrors(error);
			}
			if (response) {
				setData((prev) => ({ ...prev, ...response }));
			}
		};

		if (data.id) onHandleGetDetails();
	}, [data.id]);

	return (
		<Modal classes={{ root: "app-policy-motor-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="policy-motor-modal" aria-describedby="policy-motor-profile-modal">
			<div className="policy-motor-modal">
				<div className="policy-motor-modal__main">
					<button className="policy-motor-modal__close-button" onClick={onHandleDismiss}>
						<AppCloseIcon color="#666666" />
					</button>

					<div className="policy-motor-modal__header">
						<div className="policy-motor-modal__profile">
							<p className="policy-motor-modal__title">{data.providerName}</p>
						</div>
					</div>

					<div className="info">
						<div className="info__box">
							<p className="policy-motor-modal__text">Premium Amount</p>
							<p className="policy-motor-modal__title">RM {formatCurrency(data.totalPayable)}</p>
						</div>
						<div className="info__box">
							<p className="policy-motor-modal__text">Status</p>
							<span className="policy-motor-modal__title">
								<AppStatus status={data.status || "-"} />
							</span>
						</div>
					</div>

					<AppScrollableTabs tabs={TABS} onChange={setTab} />

					{TAB.POLICY_INFO === tab && (
						<div className="policy-motor-modal__body">
							<div className="policy-motor-modal__container">
								<div className="policy-motor-modal__wrapper">
									{productInfo.map((o, i) => {
										return (
											<div className="policy-motor-modal__info" key={i}>
												<p className="policy-motor-modal__label">{o.label}</p>
												<p className="policy-motor-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>

								<p className="policy-motor-modal__title">Policy Owner Details</p>

								<div className="policy-motor-modal__wrapper">
									{policyOwnerDetails.map((o, i) => {
										return (
											<div className="policy-motor-modal__info" key={i}>
												<p className="policy-motor-modal__label">{o.label}</p>
												<p className="policy-motor-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>

								<p className="policy-motor-modal__title">Vehicle Details</p>

								<div className="policy-motor-modal__wrapper">
									{vehicleInfo.map((o, i) => {
										return (
											<div className="policy-motor-modal__info" key={i}>
												<p className="policy-motor-modal__label">{o.label}</p>
												<p className="policy-motor-modal__value">{o.value}</p>
											</div>
										);
									})}
								</div>
							</div>

							<div className="policy-motor-modal__footer">
								<a className={downloadPolicyClassName} href={data.policyDocument} target="_blank" rel="noreferrer">
									Download Policy <UploadIcon />
								</a>
							</div>
						</div>
					)}

					{TAB.PAYMENT_HISTORY === tab && (
						<div className="policy-motor-modal__body">
							<div className="policy-motor-modal__container">
								<p className="policy-motor-modal__title">Summary</p>

								<div className="policy-motor-modal__row">
									<p className="policy-motor-modal__label">Basic Premium</p>
									<p className="policy-motor-modal__total">RM {formatCurrency(data.basicPremiumAmount)}</p>
								</div>

								<div className="policy-motor-modal__row">
									<p className="policy-motor-modal__label">No Claim Discount (NCD) {data.ncdPercentage}%</p>
									<p className="policy-motor-modal__total">- RM {formatCurrency(data?.ncdAmount)}</p>
								</div>

								<div className="policy-motor-modal__row">
									<p className="policy-motor-modal__label policy-motor-modal__label--bold">Gross Premium</p>
									<p className="policy-motor-modal__total policy-motor-modal__label--bold">RM {formatCurrency(data?.grossPremium)}</p>
								</div>

								<div className="policy-motor-modal__row">
									<p className="policy-motor-modal__label">{`SST (8%)`}</p>
									<p className="policy-motor-modal__total">RM {formatCurrency(data?.sstAmount)}</p>
								</div>

								<div className="policy-motor-modal__row">
									<p className="policy-motor-modal__label">Stamp Duty</p>
									<p className="policy-motor-modal__total">RM {formatCurrency(data?.stampDuty)}</p>
								</div>

								<div className="policy-motor-modal__row">
									<p className="policy-motor-modal__label policy-motor-modal__label--bold">Total payable:</p>
									<p className="policy-motor-modal__total policy-motor-modal__total--bold">RM {formatCurrency(data?.totalPayable || 0)}</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPolicyMotorModal));

AppPolicyMotorModal.propTypes = {
	ref: PropTypes.object,
};
