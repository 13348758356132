const eclipseString = (string = "", sliceLength = 50) => {
	const length = string.length;

	if (length > sliceLength) {
		return string.slice(0, sliceLength) + "...";
	} else {
		return string;
	}
};

export default eclipseString;
