import React, { memo } from "react";

const AppDashboardIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="dashboard-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.1 5.96457L6.815 1.06457C6.92 0.974571 7.08 0.974571 7.185 1.06457L12.9 5.96457L13 6.04957L13 11.8946C13 12.5046 12.505 12.9946 11.9 12.9946L8.635 12.9946C8.475 12.9946 8.35 12.8646 8.35 12.7096L8.35 10.2596C8.35 9.51457 7.745 8.91457 7.005 8.91457C6.265 8.91457 5.66 9.51957 5.66 10.2596L5.66 12.7096C5.66 12.8696 5.53 12.9946 5.375 12.9946L2.11 12.9946C1.5 12.9946 1.01 12.4996 1.01 11.8946L1.01 6.04957L1.11 5.96457L1.1 5.96457Z"
				stroke={color}
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default memo(AppDashboardIcon);
