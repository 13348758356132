import React, { useRef, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import ERRORS from "common/errors";
import { CALENDAR_FORMAT } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import restrictedActions from "common/restricted-actions";
import getRolesListing from "services/get-roles-listing";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppSelectStatus from "components/app-select-status";
import AppCalendarInput from "components/app-calendar-input";

const PageUser = (props) => {
	const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const defaultOptions = useRef();
	const isCreate = useMemo(() => id === COMMON.APP_STATUS.NEW, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const updateAccessible = restrictedActions(ROLES.ADMIN, isCreate ? ROLES.CREATE_ADMIN : ROLES.UPDATE_ADMIN);
	const initialValues = useMemo(() => ({ status: isCreate ? COMMON.STATUS_ID.ACTIVE : undefined, createdBy: "", dateOfBirth: "", username: "", mobile: "", prefixNo: "", email: "", role: "" }), [isCreate]);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			role: yup.string().required(ERRORS.REQUIRED),
			mobile: yup.string().required(ERRORS.REQUIRED),
			status: yup.string().required(ERRORS.REQUIRED),
			username: yup.string().required(ERRORS.REQUIRED),
			dateOfBirth: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const memoSetValues = useMemo(() => formik.setValues, [formik.setValues]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isDisabled = useMemo(() => !updateAccessible || formik.isSubmitting, [updateAccessible, formik.isSubmitting]);
	const title = useMemo(() => (isCreate ? "Add New Admin" : formik.values.username), [formik.values.username, isCreate]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				fullName: values.username,
				email: values.email,
				roleId: values.role,
				mobileNoPrefix: values.prefixNo,
				mobileNo: values.mobile,
				dateOfBirth: values.dateOfBirth,
			};
			if (isCreate) {
				await api.post.admin.create(payload);
				response = true;
			} else {
				payload.id = id;
				payload.status = values.status;
				response = await api.post.admin.update(payload);
			}
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) {
			navigate(-1);
			dispatch(promptAlertMessage({ message: isCreate ? `${values.email} has been created successfull` : `${values.email} has been updated successfull` }));
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	useEffect(() => {
		const isEdit = id !== COMMON.APP_STATUS.NEW;

		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.admin.admin(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				memoSetValues({
					status: response.status,
					dateOfBirth: response.dateOfBirth || "",
					username: response.fullName || "",
					mobile: response.mobileNo || "",
					prefixNo: response.mobileNoPrefix || "",
					email: response.email,
					role: response.role.id.toString(),
					roleName: response.role?.roleNameEn,
					createdBy: response.lastModifiedDate,
				});

				defaultOptions.current = { label: response.role.roleNameEn, value: response.role.id.toString() };
			}
		};

		if (isEdit) onHandleGetDetails();
	}, [id, memoSetValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.ADMIN.ADMIN);
		};
	}, [cancelRequest]);

	return (
		<div className="page-user">
			<div className="user">
				<h1 className="user__name">{title}</h1>

				<form className="user__form" onSubmit={formik.handleSubmit}>
					<div className="user__container">
						<div className="user__box">
							<div className="user__wrapper">
								<div className="user__box-body">
									{!isCreate && <AppSelectStatus value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} disabled={isDisabled} onChange={formik.setFieldValue} />}

									{/* prettier-ignore */}
									<AppCalendarInput name="createdBy" label="Creation Date" placeholder="-" displayFormat={calendarDisplayFormat} value={formik.values.createdBy} error={formik.errors.createdBy} touched={formik.touched.createdBy} disabled onChange={formik.setFieldValue} />

									{/* prettier-ignore */}
									<AppInput required type="text" name="username" label="User Name" placeholder="User Name" value={formik.values.username} error={formik.errors.username} touched={formik.touched.username} disabled={isDisabled} onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppInput type="text" name="email" label="Email Address" placeholder="Email Address" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} disabled={isDisabled} onChange={formik.handleChange} />

									{/* prettier-ignore */}
									<AppCalendarInput required name="dateOfBirth" label="Date of Birth" placeholder="01 Jan 2023" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.dateOfBirth} error={formik.errors.dateOfBirth} touched={formik.touched.dateOfBirth} disabled={isDisabled} onChange={formik.setFieldValue} />

									{/* prettier-ignore */}
									<AppMobileInput required type="number" name="mobile" label="Mobile No." placeholder="Mobile No." value={formik.values.mobile} prefixNo={formik.values.prefixNo} error={formik.errors.mobile} touched={formik.touched.mobile} disabled={isDisabled} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

									{/* prettier-ignore */}
									<AppSelectInput pagination required type="text" name="role" label="User Role" placeholder="Please Select" defaultOptions={defaultOptions.current} loadOptions={getRolesListing} value={formik.values.role} error={formik.errors.role} touched={formik.touched.role} disabled={isDisabled} onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					</div>

					<div className="user__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" disabled={isDisabled} label={submitLabel} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageUser;
