import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getFaqCategories = async (payload) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { page: payload?.page || 0, size: 10, sort: "title,asc", status: 1, value: payload?.keyword || "" };
		response = await api.get.general.faqCategories(params);
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getFaqCategories;
