import React, { useRef, useEffect, useMemo, useCallback, useState, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import classNames from "common/class-names";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import { CALENDAR_FORMAT, formatDatePattern, isDateBeforeToday } from "common/calendar";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppCalendarInput from "components/app-calendar-input";
import AppTable, { indexing, AppTableCell, AppTableMoreIcon } from "components/app-table";
import AppExportAuditLogModal from "components/pages/audit-log/app-export-audit-log-modal";
import AppRetrievePaymentStatusModal from "components/app-retrieve-payment-status-modal";
import retryIcon from "assets/images/retry-icon.svg";
import exportIcon from "assets/images/export-icon.svg";
import previewIcon from "assets/images/preview-icon.svg";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const AppPaymentIvoiceTable = (props) => {
	const tableRef = useRef();
	const searchInputRef = useRef();
	const exportAuditLogModalRef = useRef();
	const retrievePaymentStatusRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const paramsRef = useRef({
		tab: "INVOICE",
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || "",
		refNo: searchParams.get("refNo") || "",
		email: searchParams.get("email") || "",
		paymentRefNo: searchParams.get("paymentRefNo") || "",
		startDate: searchParams.get("startDate") || "",
		endDate: searchParams.get("endDate") || "",
	});
	const initialValues = useMemo(() => ({ refNo: "", email: "", paymentRefNo: "", startDate: "", endDate: "" }), []);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [fetchingPaymentStatus, setFetchingPaymentStatus] = useState(false);
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.refNo && !paramsRef.current.email && !paramsRef.current.paymentRefNo && !paramsRef.current.startDate && !paramsRef.current.endDate, [data.status]);

	const retrievePaymentStatusClassName = useMemo(() => classNames({ "app-table-menu__icon": true, "app-table-menu__icon--loading": fetchingPaymentStatus }), [fetchingPaymentStatus]);

	const isSearchState = useCallback(() => {
		const { tab, page, sort, ...res } = paramsRef.current;
		return Object.values(res).some((o) => o);
	}, []);
	//prettier-ignore
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			startDate: yup.string().test("beforeEndDate", ERRORS.START_DATE, function (value) {
				if (value && this.parent.endDate) return isDateBeforeToday(value, this.parent.endDate);
				else return true; 
			}),
			endDate: yup.string().test("afterStartDate", ERRORS.END_DATE, function (value) {
				if (value && this.parent.startDate) return isDateBeforeToday(this.parent.startDate || new Date(), value);
				else return true;
			}),
		}),
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const { tab, page, sort, size, startDate, endDate, ...res } = queryParamsEntries(searchParams);
		return { ...res, startDate: startDate ? formatDatePattern(startDate) : "", endDate: endDate ? formatDatePattern(endDate) : "" };
	}, [searchParams]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.auditLog.paymentInvoiceList(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable
			}));
		}
	}, []);

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
		setSelectedOrder(null);
	};

	const onHandleTableMenu = (event, obj) => {
		setSelectedOrder(obj);
		setAnchorEl(event.currentTarget);
	};

	const onHandleRetryStatus = async () => {
		setFetchingPaymentStatus(true);

		let response = null;

		try {
			response = await api.get.auditLog.invoicePaymentStatus(selectedOrder.id);
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setFetchingPaymentStatus(false);
		}

		if (response) {
			retrievePaymentStatusRef.current.onHandleShow(response);
		}
	};

	const onHandleExport = useCallback(() => {
		exportAuditLogModalRef.current.onHandleShow();
	}, []);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : "";

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	//prettier-ignore
	const AmountCell = useCallback(({ row }) => {
		return <AppTableCell right value={formatCurrency(row.original.totalAmount)} />;
	}, []);

	//prettier-ignore
	const DateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(row.original.createdDate)} />;
	}, []);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		switch(row.original.status) {
			case COMMON.STATUS_ID.FAILED:
			case COMMON.STATUS_ID.PENDING:
			case COMMON.STATUS_ID.EXPIRED:
				return <AppTableMoreIcon icon={previewIcon} onClick={(event) => onHandleTableMenu(event, row.original)} />; 
			default:
				return null;
		}
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Customer Email",
			accessor: "email",
			disableSortBy: true,
		},
		{
			Header: "Customer Name",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "Ref. No.",
			accessor: "refNo",
			disableSortBy: true,
		},
		{
			Header: "Payment Ref. No.",
			accessor: "paymentRefNo",
			disableSortBy: true,
		},
		{
            Header: <div className="table__amount">Total Amount (RM)</div>,
			accessor: "totalAmount",
			disableSortBy: true,
            Cell: AmountCell
		},
        {
			Header: "Submission Date",
			accessor: "createdDate",
			disableSortBy: false,
			Cell: DateCell,
		},
        {
			Header: "Transaction Status",
			accessor: "status",
			disableSortBy: false,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [AmountCell, DateCell, StatusCell, MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.AUDIT_LOG.PAYMENT_INVOICE_LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="app-payment-invoice-table">
			<div className="payment-invoice-table">
				{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

				{!isEmptyState && (
					<Fragment>
						{/* prettier-ignore */}
						<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttonIcon={exportIcon} buttonLabel="Export" onButtonClick={onHandleExport}>
                            <form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
                                <div className="advance-form">
                                    <div className="advance-form__inputs">
                                        <AppInput type="text" name="email" label="Customer Email" placeholder="Enter Customer Email" value={formik.values.email} onChange={formik.handleChange} />

                                        <AppInput type="text" name="refNo" label="Ref. No." placeholder="Enter Ref. No." value={formik.values.refNo} onChange={formik.handleChange} />
                                        
										<AppInput type="text" name="paymentRefNo" label="Payment Ref. No." placeholder="Enter Payment Ref. No." value={formik.values.paymentRefNo} onChange={formik.handleChange} />

										{/*prettier-ignore*/}
										<AppCalendarInput required name="startDate" label="Transaction Date (From)" placeholder="Enter Transaction Date (From)" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} />

										{/*prettier-ignore*/}
										<AppCalendarInput required name="endDate" label="Transaction Date (To)" placeholder="Enter Transaction Date (To)" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.setFieldValue} />
                                    </div>

                                    <div className="advance-form__button-container">
                                        <AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
                                        <AppButton type="submit" label="Search" />
                                    </div>
                                </div>
                            </form>
                        </AppSearchInput>

						{isSearchState() && (
							<div className="payment-invoice-table__results">
								<p className="payment-invoice-table__text">{data.totalElements} results found</p>
							</div>
						)}

						<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
					</Fragment>
				)}
			</div>

			<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleRetryStatus} disabled={fetchingPaymentStatus}>
					<img className={retrievePaymentStatusClassName} src={retryIcon} alt="retry" />
					Retry
				</MenuItem>
			</Menu>

			<AppExportAuditLogModal ref={exportAuditLogModalRef} tab="INVOICE" />

			<AppRetrievePaymentStatusModal ref={retrievePaymentStatusRef} />
		</div>
	);
};

export default AppPaymentIvoiceTable;
