import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import { formatDateTime, formatDatePattern } from "common/calendar";
import capitalizeCharacter from "common/capitalize-character";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppTable, { indexing, AppTableCell, AppTableEditIcon } from "components/app-table";
import AppRenewalModal from "components/pages/insurance-management/app-renewal-modal";
import previewIcon from "assets/images/preview-icon.svg";

const PageRenewals = (props) => {
	const tableRef = useRef();
	const searchInputRef = useRef();
	const renewalModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || defaultSortBy,
		companyName: searchParams.get("companyName") || "",
		policyNo: searchParams.get("policyNo") || "",
		refNo: searchParams.get("refNo") || "",
		statusListStr: searchParams.get("statusListStr") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const initialValues = useMemo(() => ({ policyNo: "", companyName: "", refNo: "", statusListStr: "" }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.policyNo && !paramsRef.current.companyName && !paramsRef.current.refNo && !paramsRef.current.statusListStr, [data.status]);
	const isSearchState = useCallback(() => {
		const { tab, page, sort, ...res } = paramsRef.current;
		return Object.values(res).some((o) => o);
	}, []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { policyNo: params.policyNo, companyName: params.companyName, refNo: params.refNo, statusListStr: capitalizeCharacter(params.statusListStr?.split("_")?.join(" ")) };

		return values;
	}, [searchParams]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.renewal.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable
			}));
		}
	}, []);

	const onHandleView = useCallback((id) => {
		renewalModalRef.current.onHandleShow(id);
	}, []);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		switch (id) {
			case "policyNo":
				paramsRef.current.sort = order ? "m.policyNo," + order : defaultSortBy;
				break;
			case "companyName":
				paramsRef.current.sort = order ? "c.name," + order : defaultSortBy;
				break;
			case "insuranceProviderName":
				paramsRef.current.sort = order ? "pr.name," + order : defaultSortBy;
				break;
			default:
				paramsRef.current.sort = order ? id + "," + order : defaultSortBy;

				break;
		}

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	const UpdatedByCell = useCallback(({ row }) => {
		const date = formatDateTime(new Date(row.original.lastModifiedDate));
		const updatedBy = row.original.lastModifiedByName;
		const value = `${updatedBy}, ${date}`;

		return <AppTableCell left value={value} />;
	}, []);

	const DateCreatedCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(row.original.createdDate)} />;
	}, []);

	const OfferExpiryDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(row.original.offerExpiryDate)} />;
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={previewIcon} onClick={() => onHandleView(row.original.id)} />; 
	}, [onHandleView]);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Reference Number",
			accessor: "refNo",
			disableSortBy: false,
		},
		{
			Header: "Policy No",
			accessor: "policyNo",
			disableSortBy: false,
		},
		{
			Header: "Company Name",
			accessor: "companyName",
			disableSortBy: false,
		},
		{
			Header: "Business Registration No.",
			accessor: "businessRegNo",
			disableSortBy: true,
		},
		{
			Header: "Offer expiry date",
			accessor: "offerExpiryDate",
			disableSortBy: true,
			Cell: OfferExpiryDateCell
		},
		{
			Header: "Provider",
			accessor: "insuranceProviderName",
			disableSortBy: false,
		},
		{
			Header: "Date Created",
			accessor: "createdDate",
			disableSortBy: true,
			Cell: DateCreatedCell
		},
		{
			Header: "Updated By",
			accessor: "lastModifiedDate",
			disableSortBy: true,
			Cell: UpdatedByCell
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, UpdatedByCell, OfferExpiryDateCell, DateCreatedCell, StatusCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.RENEWAL.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-renewals">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

			{!isEmptyState && (
				<div className="renewals">
					{/* prettier-ignore */}
					<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField}>
					<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
						<div className="advance-form">
							<div className="advance-form__inputs">
								<AppInput type="text" name="policyNo" label="Policy No" placeholder="Enter Policy No" value={formik.values.policyNo} onChange={formik.handleChange} />
								
								<AppInput type="text" name="companyName" label="Company Name" placeholder="Enter Company Name" value={formik.values.companyName} onChange={formik.handleChange} />
								
								<AppInput type="text" name="refNo" label="Reference No." placeholder="Enter Reference No." value={formik.values.refNo} onChange={formik.handleChange} />

								<AppSelectInput name="statusListStr" label="Status" placeholder="Select Status" options={COMMON.STATUS_DROPDOWNS.RENEWAL} value={formik.values.statusListStr} onChange={formik.handleChange} />
							</div>

							<div className="advance-form__button-container">
								<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
								<AppButton type="submit" label="Search" />
							</div>
						</div>
					</form>
				</AppSearchInput>

					{isSearchState() && (
						<div className="renewals__results">
							<p className="renewals__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
				</div>
			)}

			<AppRenewalModal ref={renewalModalRef} />
		</div>
	);
};

export default PageRenewals;
