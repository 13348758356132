import React, { memo, forwardRef, useImperativeHandle, useState, useCallback } from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import serveRequestErrors from "common/serve-request-errors";
import { CALENDAR_FORMAT, isDateBeforeToday } from "common/calendar";
import AppButton from "components/app-button";
import AppCalendarInput from "components/app-calendar-input";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

export const AppExportEndorsementModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	/* prettier-ignore */
	const formik = useFormik({
		initialValues: { startDate: "", endDate: "" },
		validationSchema: yup.object({
			startDate: yup.string().required(ERRORS.REQUIRED).test("beforeEndDate", ERRORS.START_DATE, function (value) {
				if (value && this.parent.endDate) return isDateBeforeToday(value, this.parent.endDate);
				else return true; 
			}),
			endDate: yup.string().required(ERRORS.REQUIRED).test("afterStartDate", ERRORS.END_DATE, function (value) {
				if (value && this.parent.startDate) return isDateBeforeToday(this.parent.startDate || new Date(), value);
				else return true;
			}),
		}),
		onSubmit: (values) => {
			onHandleConfirm(values);
		},
	});

	const onHandleShow = useCallback(() => {
		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
		formik.resetForm();
	}, [formik]);

	const onHandleConfirm = async (values) => {
		let response = null;
		let fileName = null;

		const transformResponse = (data, headers) => {
			fileName = headers?.["content-disposition"]?.split("attachment; filename=")?.[1]?.split('"')?.[1];

			if (fileName) return data;

			try {
				const jsonResponse = JSON.parse(new TextDecoder().decode(data));
				if (jsonResponse) return jsonResponse;
			} catch (error) {
				return data;
			}
		};

		try {
			switch (props.tab) {
				case "DEBIT_NOTE":
					response = await api.get.auditLog.exportEndorsementDebitNote(values, { transformResponse });
					break;
				case "CREDIT_NOTE":
					response = await api.get.auditLog.exportEndorsementCreditNote(values, { transformResponse });
					break;
				case "APPLICATION":
					response = await api.get.auditLog.exportPaymentApplication(values, { transformResponse });
					break;
				case "INVOICE":
					response = await api.get.auditLog.exportPaymentInvoice(values, { transformResponse });
					break;
				default:
					break;
			}
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	};

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<Modal classes={{ root: "app-export-audit-log-modal" }} open={visible} onClose={onHandleDismiss} aria-labelledby="export-audit-log-modal" aria-describedby="export-audit-log-modal">
			<form className="export-audit-log-modal" onSubmit={formik.handleSubmit}>
				<div className="export-audit-log-modal__main">
					<div className="export-audit-log-modal__header">
						<p className="export-audit-log-modal__title">Export</p>
					</div>

					<div className="export-audit-log-modal__body">
						<div className="export-audit-log-modal__content">
							{/*prettier-ignore*/}
							<AppCalendarInput required name="startDate" label="Transaction Date (From)" placeholder="Enter Transaction Date (From)" minDate={new Date("01-01-2023")} maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} disabled={formik.isSubmitting} />

							{/*prettier-ignore*/}
							<AppCalendarInput required name="endDate" label="Transaction Date (To)" placeholder="Enter Transaction Date (To)" minDate={new Date("01-01-2023")} maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate}  onChange={formik.setFieldValue} disabled={formik.isSubmitting} />
						</div>

						<div className="export-audit-log-modal__button-container">
							<AppButton type="button" label="Cancel" outline onClick={onHandleDismiss} />
							<AppButton type="submit" label="Export" disabled={formik.isSubmitting} />
						</div>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default memo(forwardRef(AppExportEndorsementModal));

AppExportEndorsementModal.propTypes = {
	ref: PropTypes.object,
};
