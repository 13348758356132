import COMMON from "common";

const validateFileSize = (files, maxSize) => {
	const size = isNaN(maxSize) ? COMMON.MAX_FILE_SIZES.MB_5 : maxSize;

	if (files?.length) {
		const uploadedFiles = files;

		const valid = uploadedFiles.some((o) => (!o.size ? true : o.size <= maxSize));
		return valid;
	} else if (files?.size) {
		return files?.size <= size;
	} else {
		return true;
	}
};

export default validateFileSize;
