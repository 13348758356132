import store from "store/store";

const restrictedActions = (main, name) => {
	let found = true;
	const systemPermission = store.getState()?.profile?.role?.systemPermission;

	if (!name) return false;

	if (systemPermission) {
		const currentSystemPermission = systemPermission.find((o) => o.name === main);

		found = currentSystemPermission?.child?.findIndex((a) => a.name === name) > -1;
	}

	return found;
};

export default restrictedActions;
