const COMMON = {
	REDUX_ACTION: {
		LOGOUT: "LOGOUT",
	},
	REDUX_PRESIST_KEY: "root_6a46285d-e323-48ae-8a84-e1bc036f0223",
	AUTH_TOKEN: "easy_cover_web_@99e53932-f4d8-46fc-9bba-757fd5e32783_auth_tk",
	REFRESH_TOKEN: "easy_cover_web_@273f71f5-0d2d-45cd-b93b-fee2c84aea7b_refresh_tk",
	ENDPOINT_PATH: {
		GENERAL: {
			PROFILE: "admin/whoami",
			OCCUPATION: "general/lookup/",
			OCCUPATION_LIST: "general/lookup/detail",
			FAQ_CATEGORIES: "general/faq-category/page",
			REFRESH_TOKEN: "authentication/public/token/refresh",
			SOCKET: "connect/ec-admin-ws",
			MAINTENANCE: "service/config/config.json",
		},
		AUTHENTICATE: {
			SIGN_IN: "authentication/public/token",
			UPDATE_PASSWORD: "admin/update/password",
			FORGOT_PASSWORD: "admin/public/reset-password/send",
			RESET_PASSWORD: "admin/public/reset-password/update",
			VERIFY_FORGOT_PASSWORD: "admin/public/reset-password/verify",
		},
		ROLES_PRIVILEGES: {
			ROLE: "role/",
			LIST: "role/search",
			CREATE: "role/create",
			UPDATE: "role/update",
			PERMISSION_LIST: "role/permission/list",
		},
		ADMIN: {
			ADMIN: "admin/",
			LIST: "admin/search",
			DELETE: "admin/remove",
			CREATE: "admin/create",
			UPDATE: "admin/update",
			UPDATE_STATUS: "admin/update-status",
		},
		AFFILIATE_PROFILE: {
			LIST: "general/affiliate/page",
			AFFILIATE: "general/affiliate/detail/",
			CREATE: "general/affiliate/create",
			UPDATE: "general/affiliate/update",
		},
		PRODUCT_EB: {
			ORDERS: "product/eb/order/page",
			ORDER: "product/eb/order/details/",
			PAYMENT_STATUS: "product/eb/order/details/payment/",
			EXPORT: "product/eb/order/export",
		},
		ENQUIRES: {
			LIST: "product/eb/enquiry/page",
			ENQUIRY: "product/eb/enquiry/details/",
			UPDATE: "product/eb/enquiry/update-status",
			UPLOAD_DOCUMENT: "product/eb/enquiry/upload/",
			DELETE_DOCUMENT: "product/eb/enquiry/delete-file",
			EXPORT: "product/eb/enquiry/export",
		},
		CLAIMS: {
			LIST: "product/eb-claim/page",
			CLAIM: "product/eb-claim/details/",
			EXPORT: "product/eb-claim/export",
		},
		POLICIES: {
			EB_LIST: "policy/eb-policy/page",
			POLICY: "policy/eb-policy/details",
			INSURER: "policy/eb-policy/insured",
			DOCUMENTS: "policy/eb-policy/documents",
			DOWNLOAD_DOCUMENTS: "policy/eb-policy/file/download",
			INVOICE_FILES: "policy/eb-invoice/files/",
			DEBIT_NOTE: "policy/eb-invoice/debit/all",
			CREDIT_NOTE: "policy/eb-invoice/credit/all",
			DOWNLOAD_INVOICES: "policy/eb-invoice/file/download",
			CREDIT_PAYMENT_HISTORY: "policy/payment-history/credit/",
			CREDIT_PAYMENT_HISTORY_RECEIPT: "policy/payment-history/credit/files/",
			DEBIT_PAYMENT_HISTORY: "policy/payment-history/debit/",
			DEBIT_PAYMENT_HISTORY_RECEIPT: "policy/payment-history/debit/files/",
			CREDIT_DOWNLOAD_RECEIPT: "policy/payment-history/credit/files/download",
			DEBIT_DOWNLOAD_RECEIPT: "policy/payment-history/debit/files/download",
			EXPORT: "policy/eb-policy/export",
		},
		MOTOR: {
			ENQUIRY: {
				LIST: "motor/enquiry/page",
				MOTOR: "motor/enquiry/details/",
				EXPORT: "motor/enquiry/export",
			},
			POLICY: {
				LIST: "motor/policy/page",
				MOTOR: "motor/policy/details/",
				EXPORT: "motor/policy/export",
			},
		},
		RENEWAL: {
			LIST: "policy/eb-policy-renew/page",
			RENEWAL: "policy/eb-policy-renew/details/",
		},
		AMENDMENT_REQUEST: {
			LIST: "employee/amendment/page",
			AMENDMENT_REQUEST: "employee/amendment/details/",
			UPDATE: "employee/amendment/update",
		},
		CUSTOMER: {
			CUSTOMER: "user/",
			LIST: "user/search",
			UPDATE: "user/update",
			UPDATE_STATUS: "user/update/status",
		},
		CAMPAIGN: {
			LIST: "ads/banner/page",
			UPDATE: "ads/banner/update",
			CREATE: "ads/banner/create",
			CAMPAIGN: "ads/banner/details/",
			UPLOAD_IMAGE: "ads/banner/upload/",
		},
		NEWS_ANNOUNCEMENT: {
			LIST: "news/page",
			CREATE: "news/create",
			UPDATE: "news/update",
			NEWS_ANNOUNCEMENT: "news/",
			UPLOAD_IMAGE: "news/upload/",
			DELETE_IMAGE: "news/delete-file/",
		},
		FAQS: {
			LIST: "general/faq/page",
			FAQ: "general/faq/details/",
			CREATE: "general/faq/create",
			UPDATE: "general/faq/update",
		},
		CONTACT_US: {
			LIST: "contact-us/drop-message/search",
			MESSAGE: "contact-us/drop-message/",
			SEND_MESSAGE: "contact-us/drop-message/add/comment",
			END_CONVERSATION: "contact-us/drop-message/end-conversation/",
		},
		AUDIT_LOG: {
			ENDORSEMENT_DEBIT_NOTE_LIST: "audit/eb/endorsement-transaction/debit/page",
			ENDORSEMENT_DEBIT_NOTE: "audit/eb/endorsement-transaction/debit/details/",
			ENDORSEMENT_CREDIT_NOTE_LIST: "audit/eb/endorsement-transaction/credit/page",
			ENDORSEMENT_CREDIT_NOTE: "audit/eb/endorsement-transaction/credit/details/",
			EXPORT_ENDORSEMENT_CREDIT_NOTE: "audit/eb/endorsement-transaction/credit/export",
			EXPORT_ENDORSEMENT_DEBIT_NOTE: "audit/eb/endorsement-transaction/debit/export",
			PAYMENT_APPLICATION_LIST: "audit/eb/payment-transaction/application/page",
			PAYMENT_INVOICE_LIST: "audit/eb/payment-transaction/invoice/page",
			EXPORT_PAYMENT_APPLICATION: "audit/eb/payment-transaction/application/export",
			EXPORT_PAYMENT_INVOICE: "audit/eb/payment-transaction/invoice/export",
			INVOICE_PAYMENT_STATUS: "audit/eb/payment-transaction/invoice/retry/",
			APPLICATION_PAYMENT_STATUS: "audit/eb/payment-transaction/application/retry/",
		},
		REPORTING: {
			REPORT_TYPES: "audit/reporting/type/list",
			GENERATE: "audit/reporting/generate",
		},
	},
	REGEX: {
		SYMBOL: /(\W|_)/,
		NUMERIC: /(?=.*\d)/,
		LOWERCASE: /(?=.*[a-z])/,
		UPPERCASE: /(?=.*[A-Z])/,
		EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	},
	MASKING: {
		NRIC: "******-**-****",
	},
	IDENTIFICATION_TYPE: {
		NRIC: "NRIC",
		PASSPORT: "PASSPORT",
	},
	POLICY: {
		PAYMENT_TYPE: {
			DEBIT_NOTE: "DEBIT_NOTE",
			CREDIT_NOTE: "CREDIT_NOTE",
		},
	},
	MAX_FILE_SIZES: {
		MB_5: 5000000,
		MB_15: 15000000,
	},
	APP_STATUS: {
		NEW: "new",
	},
	STATUS_ID: {
		NEW: "NEW",
		PAID: "PAID",
		READ: "READ",
		ACCEPTED: "ACCEPTED",
		UNPAID: "UNPAID",
		ACTIVE: "ACTIVE",
		FAILED: "FAILED",
		CLOSED: "CLOSED",
		CANCEL: "CANCEL",
		CONFIRM: "CONFIRM",
		DELETED: "DELETED",
		EXPIRED: "EXPIRED",
		PENDING: "PENDING",
		MATURED: "MATURED",
		APPROVED: "APPROVED",
		REJECTED: "REJECTED",
		IN_FORCE: "IN_FORCE",
		NOT_READ: "NOT_READ",
		INACTIVE: "INACTIVE",
		DISABLED: "DISABLED",
		REFUNDED: "REFUNDED",
		DUE_SOON: "DUE_SOON",
		SUBMITTED: "SUBMITTED",
		UNCLAIMED: "UNCLAIMED",
		WITHDRAWN: "WITHDRAWN",
		SUCCEEDED: "SUCCEEDED",
		CANCELLED: "CANCELLED",
		COMPLETED: "COMPLETED",
		DEFERMENT: "DEFERMENT",
		SUSPENDED: "SUSPENDED",
		SURRENDER: "SURRENDER",
		SUCCESSFUL: "SUCCESSFUL",
		PROCESSING: "PROCESSING",
		TERMINATED: "TERMINATED",
		IN_PROGRESS: "IN_PROGRESS",
		KEEP_IN_VIEW: "KEEP_IN_VIEW",
		PAYER_WAIVER: "PAYER_WAIVER",
		ORDER_FAILED: "ORDER_FAILED",
		PENDING_ORDER: "PENDING_ORDER",
		PENDING_POLICY: "PENDING_POLICY",
		PENDING_REVIEW: "PENDING_REVIEW",
		ORDER_REJECTED: "ORDER_REJECTED",
		PAYMENT_FAILED: "PAYMENT_FAILED",
		PAYMENT_EXPIRED: "PAYMENT_EXPIRED",
		ORDER_COMPLETED: "ORDER_COMPLETED",
		PENDING_PAYMENT: "PENDING_PAYMENT",
		PREMIUM_HOLIDAY: "PREMIUM_HOLIDAY",
		ORDER_PROCESSING: "ORDER_PROCESSING",
		TERMINATED_MANUAL: "TERMINATED_MANUAL",
		PENDING_COVER_NOTE: "PENDING_COVER_NOTE",
		CONVERSATION_ENDED: "CONVERSATION_ENDED",
		FAILED_CONFIRMATION: "FAILED_CONFIRMATION",
		REFUND_IN_PROGRESS: "REFUND_IN_PROGRESS",
		PAYMENT_IN_PROGRESS: "PAYMENT_IN_PROGRESS",
		CONDITIONAL_APPROVED: "CONDITIONAL_APPROVED",
		FORCE_CHANGE_PASSWORD: "FORCE_CHANGE_PASSWORD",
		PENDING_COMPANY_SETUP: "PENDING_COMPANY_SETUP",
		PENDING_ADDITIONAL_DOCS: "PENDING_ADDITIONAL_DOCS",
		PENDING_ADDITIONAL_INFO: "PENDING_ADDITIONAL_INFO",
		PENDING_AUTHORIZATION_APPLICABLE_FOR_B2B_MODEL: "PENDING_AUTHORIZATION_APPLICABLE_FOR_B2B_MODEL",
		PENDING_RELEASE_PAYMENT: "PENDING_RELEASE_PAYMENT",
		PENDING_QUOTATION: "PENDING_QUOTATION",
		PENDING_POLICY_NO: "PENDING_POLICY_NO",
		PENDING_ACCEPTANCE: "PENDING_ACCEPTANCE",
		REQUEST_REVISED_QUOTATION: "REQUEST_REVISED_QUOTATION",
		PENDING_REVISED_QUOTATION: "PENDING_REVISED_QUOTATION",
	},
	STATUS_CODE: {
		TOKEN_EXPIRED: "MC_000011",
		INVALID_TOKEN: "MC_000016",
		REFRESH_TOKEN_EXPIRED: "MC_000017",
		USER_BLOCKED: "MC_000018",
		MAXIMUM_PASSWORD_ATTEMPTS: "MC_UAA_00010",
	},
	STATUS_DROPDOWNS: {
		CUSTOMER: [
			{ label: "Active", value: "ACTIVE" },
			{ label: "Inactive", value: "INACTIVE" },
		],
		EB_ORDER: [
			{ label: "Pending", value: "PENDING" },
			{ label: "Pending Company Setup", value: "PENDING_COMPANY_SETUP" },
			{ label: "Order Processing", value: "ORDER_PROCESSING" },
			{ label: "Pending Additional Document", value: "PENDING_ADDITIONAL_DOCS" },
			{ label: "Order Rejected", value: "ORDER_REJECTED" },
			{ label: "Order Completed", value: "ORDER_COMPLETED" },
			{ label: "Order Cancelled", value: "ORDER_CANCELLED" },
			{ label: "Payment Failed", value: "PAYMENT_FAILED" },
			{ label: "Payment Expired", value: "PAYMENT_EXPIRED" },
			{ label: "Pending Release Payment", value: "PENDING_RELEASE_PAYMENT" },
		],
		ENQUIRES: [
			{ label: "Pending", value: "PENDING" },
			{ label: "Completed", value: "COMPLETED" },
			{ label: "Keep in View", value: "KEEP_IN_VIEW" },
			{ label: "In Progress", value: "IN_PROGRESS" },
		],
		AMENDMENT_REQUEST: [
			{ label: "Pending", value: "PENDING" },
			{ label: "Submitted", value: "SUBMITTED" },
			{ label: "Approved", value: "APPROVED" },
			{ label: "Rejected", value: "REJECTED" },
		],
		RENEWAL: [
			{ label: "Pending Quotation", value: "PENDING_QUOTATION" },
			{ label: "Pending Acceptance", value: "PENDING_ACCEPTANCE" },
			{ label: "Pending Revised Quotation", value: "PENDING_REVISED_QUOTATION" },
			{ label: "Pending Policy No", value: "PENDING_POLICY_NO" },
			{ label: "Pending Additional Docs", value: "PENDING_ADDITIONAL_DOCS" },
			{ label: "Completed", value: "COMPLETED" },
			{ label: "Rejected", value: "REJECTED" },
			{ label: "Closed", value: "CLOSED" },
			{ label: "Expired", value: "EXPIRED" },
		],
	},
	TABLE_REQUEST_STATUS: {
		INSTANCE: "INSTANCE",
	},
	INSURANCE: {
		COVERAGES: {
			ROOM_AND_BOARD: "ROOM_AND_BOARD",
		},
		PRODUCT_EB_CODE: {
			GHS: "GHS",
			GTL: "GTL",
			GO: "GO",
		},
	},
};

export default COMMON;
