import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import logo from "assets/images/logo.webp";

const PageForgotPasswordSuccess = () => {
	const navigate = useNavigate();

	const onHandleResend = useCallback(() => {
		navigate(pathnames.forgotPassword);
	}, [navigate]);

	return (
		<div className="page-forgot-password-expired">
			<div className="forgot-password-expired">
				<main className="main">
					<div className="main__content main__content--background">
						<Link to={pathnames.login} className="main__logo">
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div className="main__content main__content--content">
						<div className="main__wrapper">
							<h1 className="main__title">Uh-oh, it looks like the link has expired.</h1>
							<p className="main__description">Please request a new temporary link to access the admin portal.</p>

							<div className="main__button-container">
								<AppButton type="button" label="Resend" onClick={onHandleResend} />
							</div>
						</div>

						<p className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</p>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageForgotPasswordSuccess;
