import { redirect } from "react-router-dom";

import COMMON from "common";
import ROLES from "common/roles";
import store from "store/store";
import pathnames from "routes/pathnames";

export const protectedRoutes = ({ request, params }) => {
	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

	onHandlePermissionRoutes(request, params);

	if (token) return true;

	return redirect(pathnames.login);
};

export const publicRoutes = () => {
	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

	if (token) return redirect(pathnames.dashboard);

	return true;
};

export const resetPasswordRoutes = ({ request }) => {
	const url = new URL(request.url);
	const forgotPassword = url.searchParams.get("email") && url.searchParams.get("token");

	const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);
	const profile = store.getState()?.profile;

	if (profile.forceChangePassword && token) return true;

	if (forgotPassword) return true;

	return redirect(pathnames.dashboard);
};

const onHandlePermissionRoutes = (request, params) => {
	let found = true;
	const id = params?.id;
	const url = new URL(request.url);
	let sanitizedUrl = new URL(url);
	const profile = store.getState()?.profile;
	const roles = profile?.role;
	const systemPermission = roles?.systemPermission;

	if (id !== undefined && sanitizedUrl) {
		sanitizedUrl = sanitizedUrl.pathname.split("/");
		sanitizedUrl.pop();
		sanitizedUrl = sanitizedUrl.join("/");
	} else {
		sanitizedUrl = sanitizedUrl.pathname;
	}

	const getSystemPermissionAccess = (type) => {
		return systemPermission?.some((a) => a.name === type);
	};

	switch (sanitizedUrl) {
		case pathnames.userManagement.customer:
		case pathnames.userManagement.customers:
			found = getSystemPermissionAccess(ROLES.USER);
			break;
		case pathnames.userManagement.user:
		case pathnames.userManagement.users:
			found = getSystemPermissionAccess(ROLES.ADMIN);
			break;
		case pathnames.affiliateProfile.affiliateProfile:
		case pathnames.affiliateProfile.affiliateProfiles:
			found = getSystemPermissionAccess(ROLES.AFFILIATE);
			break;
		case pathnames.userManagement.userRolePrivileges:
		case pathnames.userManagement.userRolesPrivileges:
			found = getSystemPermissionAccess(ROLES.ROLE_PRIVILEGES);
			break;
		case pathnames.marketingInitiatives.newsAnnouncement:
		case pathnames.marketingInitiatives.newsAnnouncements:
			found = getSystemPermissionAccess(ROLES.NEWS_ANNOUNCEMENTS);
			break;
		case pathnames.marketingInitiatives.campaign:
		case pathnames.marketingInitiatives.campaigns:
			found = getSystemPermissionAccess(ROLES.CAMPAIGNS);
			break;
		case pathnames.customerSupport.faq:
		case pathnames.customerSupport.faqs:
			found = getSystemPermissionAccess(ROLES.FAQS);
			break;
		case pathnames.customerSupport.dropUsMessages:
			found = getSystemPermissionAccess(ROLES.DROP_MESSAGE);
			break;
		case pathnames.insuranceManagement.orders:
			found = getSystemPermissionAccess(ROLES.EB_PRODUCTS);
			break;
		case pathnames.insuranceManagement.enquiry:
		case pathnames.insuranceManagement.enquiries:
			found = getSystemPermissionAccess(ROLES.ENQUIRIES);
			break;
		case pathnames.insuranceManagement.amendmentRequest:
		case pathnames.insuranceManagement.amendmentRequests:
			found = getSystemPermissionAccess(ROLES.AMENDMENT_REQUEST);
			break;
		case pathnames.insuranceManagement.policies:
		case pathnames.insuranceManagement.renewals:
			found = getSystemPermissionAccess(ROLES.POLICIES);
			break;
		case pathnames.insuranceManagement.claims:
			found = getSystemPermissionAccess(ROLES.CLAIMS);
			break;
		case pathnames.auditLog.endorsement:
			found = getSystemPermissionAccess(ROLES.ENDORSEMENT_AUDIT_LOG);
			break;
		case pathnames.auditLog.payment:
			found = getSystemPermissionAccess(ROLES.PAYMENT_AUDIT_LOG);
			break;
		case pathnames.reporting:
			found = getSystemPermissionAccess(ROLES.AUDIT_REPORT);
			break;
		default:
			break;
	}

	if (!found) throw new Response("Not Found", { status: 403 });
};
