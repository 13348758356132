import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppTable, { AppTableCell, AppTableEditIcon } from "components/app-table";
import AppProductModal from "components/pages/insurance-management/app-product-modal";
import pathnames from "routes/pathnames";

const PageAmendmentRequests = (props) => {
	const tableRef = useRef();
	const navigate = useNavigate();
	const searchInputRef = useRef();
	const productModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || defaultSortBy,
		refNo: searchParams.get("refNo") || "",
		policyNo: searchParams.get("policyNo") || "",
		name: searchParams.get("name") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const initialValues = useMemo(() => ({ refNo: "", policyNo: "", name: "" }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.refNo && !paramsRef.current.policyNo && !paramsRef.current.name, [data.status]);
	const isSearchState = useCallback(() => {
		const { tab, page, sort, ...res } = paramsRef.current;
		return Object.values(res).some((o) => o);
	}, []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { refNo: params.refNo, policyNo: params.policyNo, name: params.name };

		return values;
	}, [searchParams]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.amendmentRequest.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable
			}));
		}
	}, []);

	//prettier-ignore
	const onHandleEdit = useCallback((id) => {
        navigate(pathnames.insuranceManagement.amendmentRequest + id);
    }, [navigate]);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : defaultSortBy;

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	const SubmitionDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.createdDate))} />;
	}, []);

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon onClick={() => onHandleEdit(row.original.id)} />; 
	}, [onHandleEdit]);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />
	}, []);

	//prettier-ignore
	const EmployeeIdCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.employeeId || "-"} />
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Request ID",
			accessor: "refNo",
			disableSortBy: false,
		},
		{
			Header: "Business Reg. No.",
			accessor: "businessRegNo",
			disableSortBy: false,
		},
		{
			Header: "Policy No.",
			accessor: "policyNo",
			disableSortBy: true,
		},
		{
			Header: "Employee Name",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "Employee ID",
			accessor: "employeeId",
			disableSortBy: true,
            Cell: EmployeeIdCell
		},
		{
			Header: "Submission Date",
			accessor: "createdDate",
			disableSortBy: false,
			Cell: SubmitionDateCell
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, SubmitionDateCell, StatusCell, EmployeeIdCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);
	
	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-amendment-requests">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

			{!isEmptyState && (
				<div className="amendment-requests">
					{/* prettier-ignore */}
					<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField}>
					<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
						<div className="advance-form">
							<div className="advance-form__inputs">
								<AppInput type="text" name="refNo" label="Request ID" placeholder="Enter Request ID" value={formik.values.refNo} onChange={formik.handleChange} />
								
								<AppInput type="text" name="policyNo" label="Policy No" placeholder="Enter Policy No" value={formik.values.policyNo} onChange={formik.handleChange} />
								
								<AppInput type="text" name="name" label="Employee Name" placeholder="Enter Employee Name" value={formik.values.name} onChange={formik.handleChange} />
							</div>

							<div className="advance-form__button-container">
								<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
								<AppButton type="submit" label="Search" />
							</div>
						</div>
					</form>
				</AppSearchInput>

					{isSearchState() && (
						<div className="amendment-requests__results">
							<p className="amendment-requests__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
				</div>
			)}
			<AppProductModal ref={productModalRef} />
		</div>
	);
};

export default PageAmendmentRequests;
