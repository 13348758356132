import COMMON from "common";
import pathnames from "routes/pathnames";
import AppLayout from "components/app-layout";
import { publicRoutes, protectedRoutes, resetPasswordRoutes } from "common/auth-routes";

import Page404 from "pages/page-404";
import PageMaintenance from "pages/page-maintenance";
import PageLogin from "pages/page-login";
import PageAccountSuspended from "pages/page-account-suspended";
import PageForgotPassword from "pages/forgot-password/page-forgot-password";
import PageForgotPasswordSuccess from "pages/forgot-password/page-forgot-password-success";
import PageForgotPasswordExpired from "pages/forgot-password/page-forgot-password-expired";
import PageResetPassword from "pages/forgot-password/page-reset-password";
import PageResetPasswordSuccess from "pages/forgot-password/page-reset-password-success";
import PageDashboard from "pages/page-dashboard";
import PageReporting from "pages/page-reporting";
import PageAffiliateProfile from "pages/affiliate-profile/page-affiliate-profile";
import PageAffiliateProfiles from "pages/affiliate-profile/page-affiliate-profiles";

import PageUser from "pages/user-management/page-user";
import PageUsers from "pages/user-management/page-users";
import PageCustomer from "pages/user-management/page-customer";
import PageCustomers from "pages/user-management/page-customers";
import PageUserRolePrivileges from "pages/user-management/page-user-role-privileges";
import PageUserRolesPrivileges from "pages/user-management/page-user-roles-privileges";
import PageNewsAnnouncement from "pages/marketing-initiatives/page-news-announcement";
import PageNewsAnnouncements from "pages/marketing-initiatives/page-news-announcements";
import PageCampaign from "pages/marketing-initiatives/page-campaign";
import PageCampaigns from "pages/marketing-initiatives/page-campaigns";

import PageOrders from "pages/insurance-management/page-orders";
import PageClaims from "pages/insurance-management/page-claims";
import PagePolicies from "pages/insurance-management/page-policies";
import PageEnquiry from "pages/insurance-management/page-enquiry";
import PageEnquiries from "pages/insurance-management/page-enquiries";
import PageRenewals from "pages/insurance-management/page-renewals";
import PageAmendmentRequests from "pages/insurance-management/page-amendment-requests";
import PageAmendmentRequest from "pages/insurance-management/page-amendment-request";

import PageFaq from "pages/customer-support/page-faq";
import PageFaqs from "pages/customer-support/page-faqs";
import PageContactUsMessage from "pages/customer-support/page-contact-us-messages";
import PagePayment from "pages/audit-log/page-payment";
import PageEndorsement from "pages/audit-log/page-endorsement";

const token = localStorage.getItem(COMMON.AUTH_TOKEN) || sessionStorage.getItem(COMMON.AUTH_TOKEN);

const routes = [
	{
		path: pathnames.login,
		element: <PageLogin />,
		authenticated: false,
		loader: publicRoutes,
	},
	{
		path: pathnames.forgotPassword,
		element: <PageForgotPassword />,
		authenticated: false,
		loader: publicRoutes,
	},
	{
		path: pathnames.forgotPasswordSuccess,
		element: <PageForgotPasswordSuccess />,
		authenticated: false,
		loader: publicRoutes,
	},
	{
		path: pathnames.forgotPasswordExpired,
		element: <PageForgotPasswordExpired />,
		authenticated: false,
		loader: publicRoutes,
	},
	{
		path: pathnames.resetPassword,
		element: <PageResetPassword />,
		authenticated: false,
		loader: resetPasswordRoutes,
	},
	{
		path: pathnames.resetPasswordSuccess,
		element: <PageResetPasswordSuccess />,
		authenticated: false,
		loader: publicRoutes,
	},
	{
		path: pathnames.accountSuspended,
		element: <PageAccountSuspended />,
		authenticated: false,
		loader: publicRoutes,
	},
	{
		path: pathnames.dashboard,
		element: <PageDashboard />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.reporting,
		element: <PageReporting />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.affiliateProfile.affiliateProfiles,
		element: <PageAffiliateProfiles />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.affiliateProfile.affiliateProfile + ":id",
		element: <PageAffiliateProfile />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.userManagement.user + ":id",
		element: <PageUser />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.userManagement.users,
		element: <PageUsers />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.userManagement.customer + ":id",
		element: <PageCustomer />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.userManagement.customers,
		element: <PageCustomers />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.userManagement.userRolesPrivileges,
		element: <PageUserRolesPrivileges />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.userManagement.userRolePrivileges + ":id",
		element: <PageUserRolePrivileges />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.customerSupport.dropUsMessages,
		element: <PageContactUsMessage />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.marketingInitiatives.newsAnnouncements,
		element: <PageNewsAnnouncements />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.marketingInitiatives.newsAnnouncement + ":id",
		element: <PageNewsAnnouncement />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.customerSupport.faqs,
		element: <PageFaqs />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.customerSupport.faq + ":id",
		element: <PageFaq />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.marketingInitiatives.campaigns,
		element: <PageCampaigns />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.marketingInitiatives.campaign + ":id",
		element: <PageCampaign />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.insuranceManagement.orders,
		element: <PageOrders />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.insuranceManagement.enquiries,
		element: <PageEnquiries />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.insuranceManagement.enquiry + ":id",
		element: <PageEnquiry />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.insuranceManagement.policies,
		element: <PagePolicies />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.insuranceManagement.claims,
		element: <PageClaims />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.insuranceManagement.amendmentRequests,
		element: <PageAmendmentRequests />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.insuranceManagement.amendmentRequest + ":id",
		element: <PageAmendmentRequest />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.insuranceManagement.renewals,
		element: <PageRenewals />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.auditLog.endorsement,
		element: <PageEndorsement />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.auditLog.payment,
		element: <PagePayment />,
		authenticated: true,
		loader: protectedRoutes,
		errorElement: <Page404 />,
	},
	{
		path: pathnames.maintenance,
		element: <PageMaintenance />,
		authenticated: false,
	},
	{
		path: "*",
		element: <Page404 />,
		authenticated: !!token,
	},
];

const enrichedRouters = routes.map(({ authenticated, ...res }) => {
	if (!authenticated) return { ...res };

	return { ...res, element: <AppLayout>{res.element}</AppLayout> };
});

export default enrichedRouters;
