import React, { memo } from "react";

const AppArrowIcon = ({ color = "#C0C0C0" }) => {
	return (
		<svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.96008 3.49121L1.61633 5.83496C1.52633 5.92496 1.52633 6.07496 1.61633 6.16496L3.96008 8.50871" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.455 6H1.86377" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppArrowIcon);
