import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import sanitizeObject from "common/sanitize-object";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import pathnames from "routes/pathnames";
import AppSearchInput from "components/app-search-input";
import AppTable, { indexing, AppTableCell, AppTableEditIcon } from "components/app-table";
import addIcon from "assets/images/add-icon.svg";

const PageUserRolesPrivileges = (props) => {
	const tableRef = useRef();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1 });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const createAccessible = restrictedActions(ROLES.ROLE_PRIVILEGES, ROLES.CREATE_ROLE_PRIVILEGES);

	//prettier-ignore
	const onHandleCreate = useCallback(() => {
		navigate(pathnames.userManagement.userRolePrivileges + COMMON.APP_STATUS.NEW);
	}, [navigate]);

	//prettier-ignore
	const onHandleEdit = useCallback((id) => {
		navigate(pathnames.userManagement.userRolePrivileges + id);
	}, [navigate]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.rolesPrivileges.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
			}));
		}
	}, []);

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	//prettier-ignore
	const MenuCell = useCallback((props) => {
		return <AppTableEditIcon onClick={() => onHandleEdit(props.row.original.id)} />;
	}, [onHandleEdit]);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Role",
			accessor: "roleNameEn",
			disableSortBy: true,
		},
		{
			Header: "Description",
			accessor: "descriptionEn",
			disableSortBy: true,
		},
		{
			Header: <div className="table__members">No. of Members</div>,
			HeaderLabel: "No. of Members",
			accessor: "noOfMembers",
			disableSortBy: true,
			Cell: (props) => <AppTableCell center value={props.row.original.noOfMembers} />,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.ROLES_PRIVILEGES.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-user-roles-privileges">
			<div className="roles-privileges">
				<AppSearchInput hideButton={!createAccessible} buttonLabel="Add New" buttonIcon={addIcon} onButtonClick={onHandleCreate} />

				<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} />
			</div>
		</div>
	);
};

export default PageUserRolesPrivileges;
