import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";

import COMMON from "common";
import classNames from "common/class-names";
import capitalizeCharacter from "common/capitalize-character";

const AppStatus = (props) => {
	const red = useMemo(() => {
		switch (props.status) {
			case COMMON.STATUS_ID.CLOSED:
			case COMMON.STATUS_ID.CANCEL:
			case COMMON.STATUS_ID.FAILED:
			case COMMON.STATUS_ID.EXPIRED:
			case COMMON.STATUS_ID.DELETED:
			case COMMON.STATUS_ID.INACTIVE:
			case COMMON.STATUS_ID.REJECTED:
			case COMMON.STATUS_ID.CANCELLED:
			case COMMON.STATUS_ID.SURRENDER:
			case COMMON.STATUS_ID.SUSPENDED:
			case COMMON.STATUS_ID.TERMINATED:
			case COMMON.STATUS_ID.ORDER_FAILED:
			case COMMON.STATUS_ID.PAYMENT_FAILED:
			case COMMON.STATUS_ID.ORDER_REJECTED:
			case COMMON.STATUS_ID.PAYMENT_EXPIRED:
			case COMMON.STATUS_ID.TERMINATED_MANUAL:
			case COMMON.STATUS_ID.CONVERSATION_ENDED:
			case COMMON.STATUS_ID.FAILED_CONFIRMATION:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const green = useMemo(() => {
		switch (props.status) {
			case COMMON.STATUS_ID.NEW:
			case COMMON.STATUS_ID.PAID:
			case COMMON.STATUS_ID.ACTIVE:
			case COMMON.STATUS_ID.MATURED:
			case COMMON.STATUS_ID.CONFIRM:
			case COMMON.STATUS_ID.APPROVED:
			case COMMON.STATUS_ID.REFUNDED:
			case COMMON.STATUS_ID.IN_FORCE:
			case COMMON.STATUS_ID.SUCCEEDED:
			case COMMON.STATUS_ID.COMPLETED:
			case COMMON.STATUS_ID.WITHDRAWN:
			case COMMON.STATUS_ID.DUE_SOON:
			case COMMON.STATUS_ID.SUCCESSFUL:
			case COMMON.STATUS_ID.PAYER_WAIVER:
			case COMMON.STATUS_ID.ORDER_COMPLETED:
			case COMMON.STATUS_ID.PREMIUM_HOLIDAY:
			case COMMON.STATUS_ID.CONDITIONAL_APPROVED:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const blue = useMemo(() => {
		switch (props.status) {
			case COMMON.STATUS_ID.UNPAID:
			case COMMON.STATUS_ID.PENDING:
			case COMMON.STATUS_ID.UNCLAIMED:
			case COMMON.STATUS_ID.DEFERMENT:
			case COMMON.STATUS_ID.SUBMITTED:
			case COMMON.STATUS_ID.PROCESSING:
			case COMMON.STATUS_ID.IN_PROGRESS:
			case COMMON.STATUS_ID.PENDING_REVIEW:
			case COMMON.STATUS_ID.PENDING_PAYMENT:
			case COMMON.STATUS_ID.ORDER_PROCESSING:
			case COMMON.STATUS_ID.PENDING_QUOTATION:
			case COMMON.STATUS_ID.PENDING_POLICY_NO:
			case COMMON.STATUS_ID.PENDING_ACCEPTANCE:
			case COMMON.STATUS_ID.PENDING_POLICY:
			case COMMON.STATUS_ID.PENDING_ORDER:
			case COMMON.STATUS_ID.PENDING_COVER_NOTE:
			case COMMON.STATUS_ID.PAYMENT_IN_PROGRESS:
			case COMMON.STATUS_ID.PENDING_COMPANY_SETUP:
			case COMMON.STATUS_ID.PENDING_ADDITIONAL_INFO:
			case COMMON.STATUS_ID.PENDING_ADDITIONAL_DOCS:
			case COMMON.STATUS_ID.PENDING_RELEASE_PAYMENT:
			case COMMON.STATUS_ID.PENDING_REVISED_QUOTATION:
			case COMMON.STATUS_ID.REQUEST_REVISED_QUOTATION:
			case COMMON.STATUS_ID.PENDING_AUTHORIZATION_APPLICABLE_FOR_B2B_MODEL:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const yellow = useMemo(() => {
		switch (props.status) {
			case COMMON.STATUS_ID.KEEP_IN_VIEW:
			case COMMON.STATUS_ID.REFUND_IN_PROGRESS:
				return true;
			default:
				return false;
		}
	}, [props.status]);

	const className = useMemo(() => {
		return classNames({ status: true, "status--green": green, "status--red": red, "status--blue": blue, "status--yellow": yellow });
	}, [red, green, blue, yellow]);

	const status = useMemo(() => {
		const string = props.status || "";

		switch (string) {
			case COMMON.STATUS_ID.CONVERSATION_ENDED:
				return "Closed";
			case COMMON.STATUS_ID.UNCLAIMED:
				return "Credit Balance";
			default:
				return capitalizeCharacter(string.split("_").join(" "));
		}
	}, [props.status]);

	return (
		<div className="app-status">
			<div className={className}>
				<p className="status__text">{status}</p>
			</div>
		</div>
	);
};

export default memo(AppStatus);

AppStatus.propTypes = {
	status: PropTypes.string.isRequired,
};
