import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import pathnames from "routes/pathnames";
import eclipseString from "common/eclipse-string";
import sanitizeObject from "common/sanitize-object";
import { formatDatePattern } from "common/calendar";
import queryParamsEntries from "common/query-params-entries";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppTable, { indexing, AppTableCell, AppTableEditIcon } from "components/app-table";
import addIcon from "assets/images/add-icon.svg";

const PageAffiliateProfiles = (props) => {
	const navigate = useNavigate();
	const tableRef = useRef();
	const searchInputRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, name: searchParams.get("name") || "", referralCode: searchParams.get("referralCode") || "", sort: searchParams.get("sort") || defaultSortBy });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const initialValues = useMemo(() => ({ referralCode: "", name: "" }), []);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const createAccessible = restrictedActions(ROLES.AFFILIATE, ROLES.CREATE_AFFILIATE);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.name && !paramsRef.current.referralCode, [data.status]);

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.affialiateProfile.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response.pageable,
			}));
		}
	}, []);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { name: params.name, referralCode: params.referralCode };

		return values;
	}, [searchParams]);

	const onHandleCreate = () => {
		navigate(pathnames.affiliateProfile.affiliateProfile + COMMON.APP_STATUS.NEW);
	};

	//prettier-ignore
	const onHandleEdit = useCallback((id) => {
		navigate(pathnames.affiliateProfile.affiliateProfile + id);
	}, [navigate]);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	// const onHandleSearch = (event) => {
	// 	const value = event.target.value;
	// 	paramsRef.current.name = value;
	// 	paramsRef.current.sort = "";
	// 	paramsRef.current.page = 1;

	// 	setSearchParams({ keyword: value });
	// 	tableRef.current.onHandleResetSortBy();
	// 	onHandleGetList();
	// };

	// const onHandleClearSearch = () => {
	// 	paramsRef.current.name = "";
	// 	paramsRef.current.sort = defaultSortBy;
	// 	paramsRef.current.page = 1;

	// 	setSearchParams("");
	// 	tableRef.current.onHandleResetSortBy();
	// 	onHandleGetList();
	// };

	// const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon onClick={() => onHandleEdit(row.original.id)} />; 
	}, [onHandleEdit]);

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : defaultSortBy;

		paramsRef.current.page = 1;
		onHandleGetList();
	};

	const MobileNoCell = useCallback(({ row }) => {
		return <AppTableCell left value={row.original.mobileNoPrefix + row.original.mobileNo} />;
	}, []);

	const CreatedDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(row.original.createdDate)} />;
	}, []);

	const DescriptionCell = useCallback(({ row }) => {
		return <AppTableCell left value={eclipseString(row.original.description, 100)} />;
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Affiliate Name",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "Mobile No.",
			accessor: "mobileNo",
			disableSortBy: true,
			Cell: MobileNoCell,
		},
		{
			Header: "Email Address",
			accessor: "email",
			disableSortBy: true,
		},
		{
			Header: "Description",
			accessor: "description",
			disableSortBy: true,
			Cell: DescriptionCell
		},
		{
			Header: "Affiliate Code",
			accessor: "referralCode",
			disableSortBy: false,
		},
		{
			Header: "Creation Date",
			accessor: "createdDate",
			disableSortBy: true,
			Cell: CreatedDateCell
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: false,
			Cell: ({ row }) => <AppStatus status={row.original.status} />,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, MobileNoCell, CreatedDateCell, DescriptionCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.AFFILIATE_PROFILE.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-affiliate-profiles">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={!createAccessible} onClick={onHandleCreate} />}

			{!isEmptyState && (
				<div className="affiliate-profiles">
					{/* prettier-ignore */}
					<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttonLabel="Add New" buttonIcon={addIcon} onButtonClick={onHandleCreate}>
						<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
							<div className="advance-form">
								<div className="advance-form__inputs">
									<AppInput type="text" name="referralCode" label="Affiliate Code" placeholder="Enter Affiliate Code" value={formik.values.referralCode} onChange={formik.handleChange} />

									<AppInput type="text" name="name" label="Affiliate Name" placeholder="Enter Affiliate Name" value={formik.values.name} onChange={formik.handleChange} />
								</div>

								<div className="advance-form__button-container">
									<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
									<AppButton type="submit" label="Search" />
								</div>
							</div>
						</form>
					</AppSearchInput>

					{paramsRef.current.name && (
						<div className="affiliate-profiles__results">
							<p className="affiliate-profiles__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
				</div>
			)}
		</div>
	);
};

export default PageAffiliateProfiles;
