import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";

import classNames from "common/class-names";
import AppTable from "components/app-table";
import AppLineChart from "components/app-line-chart";
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppMultiSelectInput from "components/app-multi-select-input";
import { tempDashboard } from "common/temp";

const PageDashboard = () => {
	const [chartData, setChartData] = useState([]);
	const [filterBy, setFilterBy] = useState({
		products: [],
		sort: "Today",
	});
	const [data] = useState({
		page: 1,
		size: 10,
		total: 0,
		offset: 0,
		totalElements: 0,
		prev: false,
		next: false,
		items: [
			{ policyNo: "EB-A12345678", policyHolder: "Mutu Ali", policyType: "Employee Benefits", dueDate: "09 May 2023" },
			{ policyNo: "EB-A12345678", policyHolder: "Michelle Leong", policyType: "Assets Coverage", dueDate: "11 May 2023" },
			{ policyNo: "EB-A12345678", policyHolder: "Sonu Kumar Singh", policyType: "Legal Protection", dueDate: "16 May 2023" },
			{ policyNo: "EB-A12345678", policyHolder: "Sulaiman", policyType: "Trip Protection", dueDate: "18 May 2023" },
			{ policyNo: "EB-A12345678", policyHolder: "Cheok Boon Keong", policyType: "Motor Insurance", dueDate: "21 May 2023" },
		],
	});

	const onHandleFilterChange = (v) => {
		setFilterBy((prev) => ({ ...prev, products: v }));
	};

	const onHandleSortChange = (event) => {
		const v = event.target.getAttribute("data-target");
		setFilterBy((prev) => ({ ...prev, sort: v }));
	};

	const onHandleLegendToggleData = (id) => {
		const current = tempDashboard.slice(0);
		const currentItem = chartData.findIndex((o) => o.id === id) > -1;

		if (currentItem) {
			const filtered = current.filter((o) => o.id !== id);
			setChartData(filtered);
		} else {
			const item = current.find((o) => o.id === id);
			setChartData([...chartData, item]);
		}
	};

	const CardTableHeader = useCallback(({ title }) => {
		return (
			<div className="dashboard__header">
				<p className="dashboard__headline">Policy expired in upcoming 30 days</p>
				<div className="dashboard__views">
					<p className="dashboard__text">View More</p>
					<div className="dashboard__icon">
						<AppChevronIcon color="#018897" />
					</div>
				</div>
			</div>
		);
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "Policy ID",
			accessor: "policyNo",
			disableSortBy: true,
		},
		{
			Header: "Policy Holder",
			accessor: "policyHolder",
			disableSortBy: true,
		},
		{
			Header: "Policy Type",
			accessor: "policyType",
			disableSortBy: true,
		},
		{
			Header: "Due Date",
			accessor: "dueDate",
			disableSortBy: true,
		},
	], []);

	//prettier-ignore
	const sortItems = useMemo(() => [
		{
			label: "Today",
			value: "Today"
		},
		{
			label: "Weekdly",
			value: "Weekdly"
		},
		{
			label: "Monthly",
			value: "Monthly"
		},
		{
			label: "Yearly",
			value: "Yearly"
		},
	], []);

	useEffect(() => {
		const empty = tempDashboard.map((o) => ({ ...o, data: o.data.map((o, i) => ({ ...o, y: i })) }));

		setChartData(empty);

		setTimeout(() => {
			setChartData(tempDashboard);
		}, 1000);
	}, []);

	return (
		<div className="page-dashboard">
			<div className="dashboard">
				<div className="dashboard__section">
					<div className="card">
						<div className="card__header">
							<p className="card__title">Total No. of Active Customer</p>
							<div className="dashboard__icon">
								<AppChevronIcon color="#018897" />
							</div>
						</div>
						<div className="card__body">
							<p className="card__points">
								RM <CountUp className="card__points" end={50000000} />
							</p>
						</div>
						<div className="card__footer">
							<div className="card__results">
								<p className="card__no card__no--positive">6800</p>
								<p className="card__label">Registered </p>
							</div>
							<div className="card__results">
								<p className="card__no card__no--warning">165</p>
								<p className="card__label">Inactive</p>
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card__header">
							<p className="card__title">Total No. of Sales Transactions</p>
							<div className="dashboard__icon">
								<AppChevronIcon color="#018897" />
							</div>
						</div>
						<div className="card__body">
							<p className="card__points">
								RM <CountUp className="card__points" end={8000} />
							</p>
						</div>
						<div className="card__footer">
							<div className="card__values">
								<div className="card__downturn card__downturn--increase" />
								<p className="card__text">26%</p>
							</div>
							<div className="card__values">
								<p className="card__text">
									<span className="card__price card__price--positive">+ RM 128K</span> this month
								</p>
							</div>
						</div>
					</div>

					<div className="card">
						<div className="card__header">
							<p className="card__title">Total Sales of Canceled/Refund</p>
							<div className="dashboard__icon">
								<AppChevronIcon color="#018897" />
							</div>
						</div>
						<div className="card__body">
							<p className="card__points">
								RM <CountUp className="card__points" end={19} />
							</p>
						</div>
						<div className="card__footer">
							<div className="card__values">
								<div className="card__downturn card__downturn--increase" />
								<p className="card__text">8%</p>
							</div>
							<div className="card__values">
								<p className="card__text">
									<span className="card__price card__price--warning">- RM 19K</span> this month
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="dashboard__section">
					<div className="card-chart">
						<p className="card-chart__title">Revenue based by Protection</p>

						<div className="card-chart__header">
							{/* prettier-ignore */}
							<AppMultiSelectInput name="filter" placeholder="Filter" value={filterBy.products} onChange={onHandleFilterChange}
								options={[
									{ label: "MSME", value: "MSME" },
									{ label: "Employee Benefits", value: "Employee Benefits" },
									{ label: "Assets Coverage", value: "Assets Coverage" },
									{ label: "Legal Protection", value: "Legal Protection" },
								]}
							/>

							<ul className="card-chart__list">
								{sortItems.map((o, i) => {
									const itemClassName = classNames({ "card-chart__item": true, "card-chart__item--active": filterBy.sort === o.value });
									return (
										<li key={i} className={itemClassName} onClick={onHandleSortChange} data-target={o.value}>
											{o.label}
										</li>
									);
								})}
							</ul>
						</div>

						<AppLineChart data={chartData} dataLegends={tempDashboard} onLegendToggle={onHandleLegendToggleData} />
					</div>
				</div>

				<div className="dashboard__section">
					<div className="dashboard__container">
						<CardTableHeader title="Policy expired in upcoming 30 days" />

						<div className="dashboard__body">
							<div className="card-table">
								<AppTable columns={columns} pages={data} />
							</div>
						</div>
					</div>

					<div className="dashboard__container">
						<CardTableHeader title="Claim Summary in last 30 days" />

						<div className="dashboard__body">
							<div className="card-table">
								<AppTable columns={columns} pages={data} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageDashboard;
