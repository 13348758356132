const pathnames = {
	login: "/",
	accountSuspended: "/account-suspended",
	forgotPassword: "/forgot-password",
	forgotPasswordSuccess: "/forgot-password/success",
	forgotPasswordExpired: "/forgot-password/expired",
	resetPassword: "/reset-password",
	resetPasswordSuccess: "/reset-password/success",
	dashboard: "/dashboard",
	reporting: "/reporting",
	maintenance: "/maintenance",
	affiliateProfile: {
		affiliateProfile: "/affiliate-profile/affiliate-profile/",
		affiliateProfiles: "/affiliate-profile/affiliate-profiles",
	},
	userManagement: {
		users: "/user-management/users",
		user: "/user-management/user/",
		customer: "/user-management/customer/",
		customers: "/user-management/customers",
		userRolePrivileges: "/user-management/user-role-privileges/",
		userRolesPrivileges: "/user-management/user-roles-privileges",
	},
	marketingInitiatives: {
		newsAnnouncements: "/marketing-initiatives/news-announcements",
		newsAnnouncement: "/marketing-initiatives/news-announcement/",
		campaigns: "/marketing-initiatives/campaigns",
		campaign: "/marketing-initiatives/campaign/",
	},
	insuranceManagement: {
		orders: "/insurance-management/orders",
		enquiries: "/insurance-management/enquries",
		enquiry: "/insurance-management/enquiry/",
		policies: "/insurance-management/policies",
		claims: "/insurance-management/claims",
		amendmentRequests: "/insurance-management/amendment-requests",
		amendmentRequest: "/insurance-management/amendment-request/",
		renewals: "/insurance-management/renewals",
	},
	auditLog: {
		payment: "/audit-log/payment",
		endorsement: "/audit-log/endorsement",
	},
	customerSupport: {
		faqs: "/customer-support/faqs",
		faq: "/customer-support/faq/",
		dropUsMessages: "/customer-support/drop-us-messages",
	},
};

export default pathnames;
