import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import classNames from "common/class-names";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import capitalizeCharacter from "common/capitalize-character";
import { formatCurrency } from "common/format-currency-pattern";
import AppTabs from "components/app-tabs";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppProductModal from "components/pages/insurance-management/app-product-modal";
import AppTable, { indexing, AppTableCell, AppTableMoreIcon } from "components/app-table";
import AppRetrievePaymentStatusModal from "components/app-retrieve-payment-status-modal";
import AppExportReportModal, { TYPES } from "components/pages/insurance-management/app-export-report-modal";
import retryIcon from "assets/images/retry-icon.svg";
import exportIcon from "assets/images/export-icon.svg";
import previewIcon from "assets/images/preview-icon.svg";

const PageOrders = (props) => {
	const tableRef = useRef();
	const searchInputRef = useRef();
	const productModalRef = useRef();
	const exportReportModalRef = useRef();
	const retrievePaymentStatusRef = useRef();
	const [anchorEl, setAnchorEl] = useState(null);
	const [fetchingPaymentStatus, setFetchingPaymentStatus] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || defaultSortBy,
		custName: searchParams.get("custName") || "",
		orderNo: searchParams.get("orderNo") || "",
		businessRegistrationNo: searchParams.get("businessRegistrationNo") || "",
		companyName: searchParams.get("companyName") || "",
		status: searchParams.get("status") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const tabsNav = useMemo(() => [{ label: "Employee Benefit", value: "EMPLOYEE_BENEFIT" }], []);
	const initialValues = useMemo(() => ({ orderNo: "", companyName: "", custName: "", businessRegistrationNo: "", status: "" }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.custName && !paramsRef.current.orderNo && !paramsRef.current.businessRegistrationNo && !paramsRef.current.companyName && !paramsRef.current.status, [data.status]);
	const isSearchState = useCallback(() => {
		const { tab, page, sort, ...res } = paramsRef.current;
		return Object.values(res).some((o) => o);
	}, []);
	const fetchPaymentStatus = useMemo(() => {
		if (!selectedOrder?.paymentStatus) return false;

		switch (selectedOrder.paymentStatus) {
			case COMMON.STATUS_ID.EXPIRED:
			case COMMON.STATUS_ID.FAILED:
			case COMMON.STATUS_ID.PENDING:
				return true;
			default:
				return false;
		}
	}, [selectedOrder]);

	const retrievePaymentStatusClassName = useMemo(() => classNames({ "app-table-menu__icon": true, "app-table-menu__icon--loading": fetchingPaymentStatus }), [fetchingPaymentStatus]);

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { orderNo: params.orderNo, companyName: params.companyName, custName: params.custName, businessRegistrationNo: params.businessRegistrationNo, status: capitalizeCharacter(params.status?.split("_")?.join(" ")) };

		return values;
	}, [searchParams]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.productEb.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable
			}));
		}
	}, []);

	const onhandleCloseMenu = () => {
		setAnchorEl(null);
		setSelectedOrder(null);
	};

	const onHandleTableMenu = (event, obj) => {
		setSelectedOrder(obj);
		setAnchorEl(event.currentTarget);
	};

	const onHandleViewApplication = useCallback(() => {
		productModalRef.current.onHandleShow(selectedOrder.id);
	},[selectedOrder?.id]);

	const onHandleExportReport = useCallback(() => {
		exportReportModalRef.current.onHandleShow();
	}, []);

	const onHandleRetryStatus = async () => {
		setFetchingPaymentStatus(true);

		let response = null;

		try {
			response = await api.get.productEb.paymentStatus(selectedOrder.id);
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setFetchingPaymentStatus(false);
		}

		if (response) {
			retrievePaymentStatusRef.current.onHandleShow(response);
		}
	};

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : defaultSortBy;

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableMoreIcon icon={previewIcon} onClick={(event) => onHandleTableMenu(event, row.original)} />; 
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Order Ref. No.",
			accessor: "orderNo",
			disableSortBy: false,
		},
		{
			Header: "Customer Name",
			accessor: "createdByName",
			disableSortBy: true,
		},
		{
			Header: "Product Name",
			accessor: "productName",
			disableSortBy: true,
		},
		{
			Header: "Organisation Name",
			accessor: "ebCompany.companyName",
			disableSortBy: true,
		},
		{
			Header: "Business Reg. No.",
			accessor: "ebCompany.businessRegistrationNo",
			disableSortBy: true,
		},
		{
			Header: "No. of Employees",
			accessor: "totalNoOfEmployee",
			disableSortBy: true,
			Cell: ({ row }) => <AppTableCell center value={row.original.totalNoOfEmployee} />,
		},
		{
			Header: "Gross Premium Amount (RM)",
			accessor: "annualPremiumPrice",
			disableSortBy: true,
			Cell: ({ row }) => <AppTableCell right value={formatCurrency(row.original.annualPremiumPrice)} />,
		},
		{
			Header: "Order Submission Date",
			accessor: "createdDate",
			disableSortBy: false,
			Cell: ({ row }) => <AppTableCell left value={formatDatePattern(new Date(row.original.createdDate))} />,
		},
		{
			Header: "Status",
			accessor: "orderStatus",
			disableSortBy: true,
			Cell: ({ row }) => <AppStatus status={row.original.orderStatus} />,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.PRODUCT_EB.ORDERS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-orders">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

			{!isEmptyState && (
				<div className="orders">
					<AppTabs tabs={tabsNav} onChange={() => {}} />

					{/* prettier-ignore */}
					<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttonLabel="Export" buttonIcon={exportIcon} onButtonClick={onHandleExportReport}>
					<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
						<div className="advance-form">
							<div className="advance-form__inputs">
								<AppInput type="text" name="orderNo" label="Order Ref. No." placeholder="Enter Order Ref. No." value={formik.values.orderNo} onChange={formik.handleChange} />
								
								<AppInput type="text" name="custName" label="Customer Name" placeholder="Enter Customer Name" value={formik.values.custName} onChange={formik.handleChange} />
								
								<AppInput type="text" name="companyName" label="Organisation Name" placeholder="Enter Organisation Name" value={formik.values.companyName} onChange={formik.handleChange} />
								
								<AppInput type="text" name="businessRegistrationNo" label="Business Reg. No." placeholder="Enter Business Reg. No." value={formik.values.businessRegistrationNo} onChange={formik.handleChange} />

								<AppSelectInput name="status" label="Status" placeholder="Select Status" options={COMMON.STATUS_DROPDOWNS.EB_ORDER} value={formik.values.status} onChange={formik.handleChange} />
							</div>

							<div className="advance-form__button-container">
								<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
								<AppButton type="submit" label="Search" />
							</div>
						</div>
					</form>
				</AppSearchInput>

					{isSearchState() && (
						<div className="orders__results">
							<p className="orders__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
				</div>
			)}

			<Menu classes={{ root: "app-table-menu" }} anchorEl={anchorEl} open={!!anchorEl} onClose={onhandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleViewApplication}>
					<img className="app-table-menu__icon" src={previewIcon} alt="view" />
					View
				</MenuItem>

				{fetchPaymentStatus && (
					<MenuItem onClick={onHandleRetryStatus} disabled={fetchingPaymentStatus}>
						<img className={retrievePaymentStatusClassName} src={retryIcon} alt="retry" />
						Retry
					</MenuItem>
				)}
			</Menu>
			<AppProductModal ref={productModalRef} />

			<AppRetrievePaymentStatusModal ref={retrievePaymentStatusRef} />

			<AppExportReportModal ref={exportReportModalRef} type={TYPES.APPLICATION} />
		</div>
	);
};

export default PageOrders;
