import React, { memo, useCallback, useState, useMemo } from "react";
import { FormControl, Select, MenuItem, ListItemText, FormHelperText, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";

import AppIcon from "components/app-icon";
import classNames from "common/class-names";
import AppCheckbox from "components/app-checkbox";
import AppChevronIcon from "components/icons/app-chevron-icon";
import lockIcon from "assets/images/components/app-input/lock-icon.svg";
import filterIcon from "assets/images/components/app-multi-select-input/filter-icon.svg";

const AppMultiSelectInput = (props) => {
	const [selections, setSelections] = useState(props.value);
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);

	const className = useMemo(() => {
		return classNames({
			"app-multi-select-input": true,
			"app-multi-select-input--disabled": props.disabled,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.disabled]);

	const selectionValues = useMemo(() => selections.length, [selections]);

	const onHandleChange = (event) => {
		const value = event.target.value;
		const newSelections = typeof value === "string" ? value.split(",") : value;

		setSelections(newSelections);
		if (props.onChange) props.onChange(newSelections);
	};

	//prettier-ignore
	const SelectIcon = useCallback((res) => {
		if (props.disabled) {
			return (
				<InputAdornment position="end">
					<AppIcon src={lockIcon} />
				</InputAdornment>
			);
		}

		return (
			<div className="app-multi-select-input__icon MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined">
				<AppChevronIcon {...res} color="#018897" />
			</div>
		);
	}, [props.disabled]);

	//prettier-ignore
	const StartEndorment = useCallback(() => (
		<InputAdornment position="start">
			<AppIcon src={filterIcon} />
		</InputAdornment>
	), []);

	return (
		<div className={className}>
			<FormControl error={isErrorField}>
				<label className="app-multi-select-input__label" htmlFor={props.name}>
					{props.label}
					{props.required && <span className="app-multi-select-input__required">*</span>}
				</label>

				<span className="app-multi-select-input__totals">{selectionValues}</span>

				{/* prettier-ignore */}
				<Select multiple displayEmpty name={props.name} value={selections} onChange={onHandleChange} disabled={props.disabled} renderValue={(selected) => props.placeholder} IconComponent={(props) => <SelectIcon {...props} />} MenuProps={{ classes: { root: "app-multi-select-input-modal" } }} startAdornment={<StartEndorment />}>
					
					{props.options?.map((o, i) => {
						const checked = selections.indexOf(o.value) > -1;
						return (
							<MenuItem key={i} value={o.value}>
								<AppCheckbox label="" name={o.value} value={checked} onClick={() => {}} />
								<ListItemText primary={o.label} />
							</MenuItem>
						);
					})}
				</Select>

				<FormHelperText>{errorMessage}</FormHelperText>
			</FormControl>
		</div>
	);
};

AppMultiSelectInput.propTypes = {
	error: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	cancelId: PropTypes.string,
	pagination: PropTypes.bool,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	defaultOptions: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
		}).isRequired
	),
};

export default memo(AppMultiSelectInput);
