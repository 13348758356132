import React, { memo, useRef, useContext, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import { setProfile } from "store/slices/profile";
import serveRequestErrors from "common/serve-request-errors";
import { AxiosContext } from "contexts/with-interceptor-provider";
import AppAlert from "components/app-alert";
import AppHeader from "components/app-header";
import AppSideNav from "components/app-side-nav";

const AppLayout = ({ children }) => {
	const sideNavRef = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { profile } = useSelector((state) => state);
	const [title, setTitle] = useState("");
	const props = useContext(AxiosContext);

	const onHandleSetTitle = useCallback((text) => {
		setTitle(text);
	}, []);

	const onHandleToggleSideNav = useCallback(() => {
		sideNavRef.current.onHandleToggleSideNav();
	}, []);

	const onHandleGetProfile = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.general.profile();
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) dispatch(setProfile(response));
	}, [dispatch]);

	useEffect(() => {
		onHandleGetProfile();
	}, [onHandleGetProfile]);

	useEffect(() => {
		const isForceChangePassword = profile.forceChangePassword === COMMON.STATUS_ID.FORCE_CHANGE_PASSWORD;
		if (isForceChangePassword) navigate(pathnames.resetPassword, { replace: true });
	}, [profile.forceChangePassword, navigate]);

	useEffect(() => {
		return () => {
			props?.onHandleCancelRequest(COMMON.ENDPOINT_PATH.GENERAL.PROFILE);
		};
	}, [props]);

	return (
		<div className="app-layout">
			<AppAlert />

			<main className="main">
				<AppSideNav ref={sideNavRef} onHandleSetTitle={onHandleSetTitle} />

				<div id="layout-container" className="main__container">
					<AppHeader title={title} onHandleToggleSideNav={onHandleToggleSideNav} />


					<div className="main__page">{React.cloneElement(children, { ...props })}</div>

					<footer className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</footer>
				</div>
			</main>
		</div>
	);
};

export default memo(AppLayout);
