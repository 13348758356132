import React, { memo, useCallback, useEffect, useMemo, forwardRef, useImperativeHandle, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import COMMON from "common";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import sideNavList from "common/side-nav-list";
import AppLogoutIcon from "components/icons/app-logout-icon";
import logo from "assets/images/logo-white.png";

const AppSideNav = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [activeIndex, setActiveIndex] = useState(0);
	const initialzieSideNav = useMemo(() => sideNavList.map((cloned) => ({ ...cloned })), []);
	const profile = useSelector((state) => state.profile);
	const onHandleSetTitle = useMemo(() => props.onHandleSetTitle, [props.onHandleSetTitle]);

	const enrichedSideNav = useMemo(() => {
		const roles = profile?.role?.systemPermission;

		if (!roles) return [];

		//prettier-ignore
		const sideNav = initialzieSideNav.map((a) => {
			if (a.childrens.length) {
				const filtered = a.childrens.filter((b) => !b.name || roles.some((c) => c.name === b.name));

				if (filtered.length) {
					a.childrens = filtered;
					return a;
				}

				return null;
			} else if (a.names?.length) {
				const accessisble = roles.findIndex((c) => a.names.includes(c.name)) > -1;

				if (accessisble) return a;

				return null;
			} else {
				return a;
			}
			}).filter((o) => o);

		return sideNav;
	}, [initialzieSideNav, profile.role]);

	//prettier-ignore
	const itemLabelClassName = useCallback((currentIndex) => {
		return classNames({ "side-nav__label": true, "side-nav__label--active": activeIndex === currentIndex });
	}, [activeIndex]);

	//prettier-ignore
	const itemChildLabelClassName = useCallback((pathname, relatives) => {
		const samePath = location.pathname === pathname;
		const relativesActive = relatives.findIndex(o => location.pathname.includes(o)) > -1;
		return classNames({ "side-nav__label": true, "side-nav__label--child-active": samePath || relativesActive });
	}, [location]);

	//prettier-ignore
	const iconColor = useCallback((currentIndex) => {
		return currentIndex === activeIndex ? "#018897" : undefined;
	}, [activeIndex]);

	const onHandleLogout = useCallback(() => {
		localStorage.clear();
		sessionStorage.clear();
		dispatch({ type: COMMON.REDUX_ACTION.LOGOUT });
		navigate(pathnames.login);
	}, [dispatch, navigate]);

	const onHandleToggleSideNav = () => {
		const sideNav = document.getElementById("side-nav");
		const appAlert = document.getElementById("app-alert");
		const layoutMenu = document.getElementById("header-menu");
		const layoutContainer = document.getElementById("layout-container");

		if (appAlert) appAlert.classList.toggle("app-alert--shrinked");
		sideNav.classList.toggle("app-side-nav--shrinked");
		layoutMenu.classList.toggle("header__menu--shrinked");
		layoutContainer.classList.toggle("main__container--shrinked");
	};

	//prettier-ignore
	const onHandleHighlight = useCallback((index) => {
		setActiveIndex(index);
		
		setTimeout(() => {
			const items = document.querySelectorAll(".side-nav__item");
			const sideNavTarget = document.getElementById("side-nav-target");
			if(sideNavTarget && items[index]) return sideNavTarget.style.top = `${items[index]?.offsetTop}px`;
			else if(sideNavTarget) return sideNavTarget.style.top = `-300px`;
		});
	}, []);

	//prettier-ignore
	const onHandleItemChange = useCallback((obj, index) => {
		onHandleHighlight(index);
		
		if(!obj.childrens.length) {
			onHandleSetTitle(enrichedSideNav[index].label);
		}
		else {
			onHandleSetTitle(enrichedSideNav[index].childrens[0].label);
		}

		if(obj.childrens.length) {
			navigate(obj.childrens[0].path);
		}
		else {
			navigate(obj.path);
		}
	}, [enrichedSideNav, navigate, onHandleHighlight, onHandleSetTitle]);

	//prettier-ignore
	const onHandleChildChange = useCallback((event, obj) => {
		const isMobile = window.innerWidth <= 768;

		event.stopPropagation();
		onHandleSetTitle(obj.label);
		navigate(obj.path);

		if(isMobile) onHandleToggleSideNav();
	}, [navigate, onHandleSetTitle]);

	//prettier-ignore
	const SideNavChildrens = useCallback((obj) => {
		if (!obj.active) return null;

		return obj.childrens.map((j, k) => {
			return (
				<div className="side-nav__children" key={k} onClick={(event) => obj.onHandleChildChange(event, j)}>
					<p className={obj.itemChildLabelClassName(j.path, j.relatives)}>{j.label}</p>
				</div>
			);
		});
	}, []);

	useEffect(() => {
		/* Set Nav Background To Specify Position */
		let sideNavIndex = null;

		const childrensIndex = enrichedSideNav.findIndex((o) => {
			return (
				o.childrens?.findIndex((j) => {
					const isChildrenPath = location.pathname === j.path;

					if (!isChildrenPath) {
						const relativeIndex = j.relatives.findIndex((i) => location.pathname.includes(i));
						return relativeIndex > -1;
					} else {
						onHandleSetTitle(j.label);
						return true;
					}
				}) > -1
			);
		});

		if (childrensIndex > -1) {
			sideNavIndex = childrensIndex;
		} else {
			const mainIndex = enrichedSideNav.findIndex((o) => {
				const isMainPath = o.path === location.pathname;

				if (isMainPath) return true;
				else if (o.relatives?.length) return o.relatives.find((j) => location.pathname.includes(j));
				else return false;
			});

			if (mainIndex > -1) {
				sideNavIndex = mainIndex;
				onHandleSetTitle(enrichedSideNav[mainIndex]?.label);
			} else {
				onHandleSetTitle("404");
			}
		}

		if (id) onHandleSetTitle("");

		onHandleHighlight(sideNavIndex);
	}, [id, enrichedSideNav, onHandleSetTitle, location, onHandleHighlight]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleToggleSideNav: onHandleToggleSideNav,
	}));

	return (
		<div id="side-nav" className="app-side-nav">
			<div className="side-nav">
				<div className="side-nav__logo" onClick={onHandleToggleSideNav}>
					<img src={logo} alt="logo" />
				</div>

				<nav className="side-nav__nav">
					<ul className="side-nav__list">
						<div id="side-nav-target" className="side-nav__background" />

						{enrichedSideNav.map((o, i) => {
							const Icon = o.icon;
							const activeItem = activeIndex === i;

							return (
								<li className="side-nav__item" key={i} onClick={() => onHandleItemChange(o, i)}>
									<div className="side-nav__wrapper">
										<div className="side-nav__content">
											<div className="side-nav__icon">
												<Icon color={iconColor(i)} />
											</div>
											<p className={itemLabelClassName(i)}>{o.label}</p>
										</div>
									</div>

									<SideNavChildrens childrens={o.childrens} active={activeItem} onHandleChildChange={onHandleChildChange} itemChildLabelClassName={itemChildLabelClassName} />
								</li>
							);
						})}
					</ul>
					<div className="side-nav__item side-nav__item--logout" onClick={onHandleLogout}>
						<div className="side-nav__wrapper">
							<div className="side-nav__icon">
								<AppLogoutIcon />
							</div>
							<p className="side-nav__label">Logout</p>
						</div>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppSideNav));

AppSideNav.propTypes = {
	onHandleSetTitle: PropTypes.func.isRequired,
};
