import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import ERRORS from "common/errors";
import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import logo from "assets/images/logo.webp";

const PageForgotPassword = () => {
	const navigate = useNavigate();
	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: yup.object({
			email: yup.string().matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL).required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleSubmit = async (values) => {
		try {
			await api.post.authenticate.forgotPassword(values);
			navigate(pathnames.forgotPasswordSuccess);
		} catch (error) {
			serveRequestErrors(error, (msg) => {
				formik.setFieldError("email", msg);
			});
		} finally {
			formik.setSubmitting(false);
		}
	};

	return (
		<div className="page-forgot-password">
			<div className="forgot-password">
				<main className="main">
					<div className="main__content main__content--background">
						<Link to={pathnames.login} className="main__logo">
							<img src={logo} alt="logo" />
						</Link>
					</div>

					<div className="main__content main__content--form">
						<form className="main__form" onSubmit={formik.handleSubmit}>
							<h1 className="main__title">Oops! It looks like you've forgotten your password.</h1>
							<p className="main__description">No worries, it happens to the best of us. Please enter your email address and we'll send you a link to reset your password.</p>

							{/* prettier-ignore */}
							<AppInput type="text" name="email" label="Email Address" placeholder="Email Address" error={formik.errors.email} touched={formik.touched.email} disabled={formik.isSubmitting} onChange={formik.handleChange} />

							<div className="main__button-container">
								<AppButton type="submit" label="Reset" disabled={formik.isSubmitting} />
							</div>
						</form>

						<p className="main__copyright">© {new Date().getFullYear()} Easycover.my. All Rights Reserved.</p>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageForgotPassword;
