import JSEncrypt from "jsencrypt";

const stringEncryptRSA = (string = "") => {
	const encrypt = new JSEncrypt();

	encrypt.setPublicKey(process.env.REACT_APP_RSA_PUBLIC_KEY);

	const encryptedString = encrypt.encrypt(string);

	return encryptedString;
};

export default stringEncryptRSA;
