import React, { memo, useCallback, useState, useMemo, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSearchParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { useTable, useRowSelect, useSortBy } from "react-table";

import classNames from "common/class-names";
import AppArrowIcon from "components/icons/app-arrow-icon";
import editIcon from "assets/images/edit-icon.svg";
import AppCheckIcon from "components/icons/app-check-icon";
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppMultiSelectInput from "components/app-multi-select-input";
import moreIcon from "assets/images/components/app-table/more-icon.svg";
import sortbyIcon from "assets/images/components/app-table/sortby-icon.svg";
import { ReactComponent as MinusIcon } from "assets/images/components/app-table/minus-icon.svg";

export const indexing = (page, i) => {
	return page > 1 ? (10 * (page - 1) + i + 1).toString().padStart(2, "0") : (i + 1).toString().padStart(2, "0");
};

export const AppTableMoreIcon = memo(({ ...props }) => {
	return (
		<button className="table__action" {...props}>
			<img src={moreIcon} alt="action" />
		</button>
	);
});

export const AppTableEditIcon = memo(({ ...props }) => {
	return (
		<button className="table__action" {...props}>
			<img src={props.icon || editIcon} alt="action" />
		</button>
	);
});

export const AppTableCell = memo((props) => {
	const className = useMemo(() => {
		return classNames({
			"table__center--values": props.center,
			"table__left--values": props.left,
			"table__right--values": props.right,
			...(props.className && { [props.className]: true }),
		});
	}, [props.center, props.left, props.right, props.className]);

	return <div className={className}>{props.value}</div>;
});

const IndeterminateCheckbox = (props) => {
	const className = useMemo(() => {
		return classNames({
			table__checkbox: true,
			"table__checkbox--active": props.checked,
			"table__checkbox--indeterminate": props.indeterminate,
		});
	}, [props.checked, props.indeterminate]);

	//prettier-ignore
	const onHandleChange = useCallback((event) => {
		event.target.checked = !props.checked;
		props.onChange(event);
	}, [props]);

	return (
		<button type="button" className={className} onClick={onHandleChange}>
			{props.checked && <AppCheckIcon className="table__check-icon" />}
			{props.indeterminate && <MinusIcon />}
		</button>
	);
};

const AppTable = forwardRef((props, ref) => {
	const [searchParams] = useSearchParams();
	const sortParams = useMemo(() => {
		const status = searchParams.get("status");
		const params = searchParams.get("sort")?.split(",");

		if (params) {
			const id = params[0]?.split(".")?.[0];
			return { id: id || params[0], order: params[1] };
		}

		if (status) {
			return { id: "status", order: status === 1 ? "asc" : "desc" };
		}

		return {};
	}, [searchParams]);
	const [sortBy, setSortBy] = useState(sortParams);
	const [visibleColumns, setVisibleColumns] = useState([]);

	//prettier-ignore
	const config = useMemo(() => ({
		data: props.pages.items,
		columns: props.columns,
		manualSorting: true,
		initialState: {
			hiddenColumns: props.columns.map((column) => (column.show === false ? column.accessor || column.id : null))
		},
	}), [props.pages, props.columns]);

	const nextAvailableColor = useMemo(() => (props.pages?.next ? "#018897" : undefined), [props.pages?.next]);
	const prevAvailableColor = useMemo(() => (props.pages?.prev ? "#018897" : undefined), [props.pages?.prev]);
	const totalPageItems = useMemo(() => Array.from(Array(props.pages?.total || 1).keys()), [props.pages?.total]);
	const visibleColumnOptions = useMemo(() => {
		const options = props.columns.map((o) => ({ label: o.HeaderLabel || o.Header, value: o.accessor }));
		return options.filter((o) => !(o.label === "#" || !o.label));
	}, [props.columns]);

	//prettier-ignore
	const onHandleCheckbox = useCallback((hooks) => {
		if(!props.checker) return;
		hooks.visibleColumns.push((columns) => [
			{
				id: "selection",
				disableSortBy: true,
				Header: ({ getToggleAllRowsSelectedProps }) => <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />,
				Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
			},
			...columns,
		]);
	}, [props.checker]);

	const { getTableProps, getTableBodyProps, setHiddenColumns, headerGroups, rows, prepareRow, selectedFlatRows, isAllRowsSelected } = useTable(config, useSortBy, useRowSelect, onHandleCheckbox);

	const sortbyClassName = useCallback((desc, inactive) => {
		return classNames({
			table__sortby: true,
			"table__sortby--desc": desc,
			"table__sortby--inactive": inactive,
		});
	}, []);

	//prettier-ignore
	const onHandleResetSortBy = useCallback(() => {
		setSortBy({});
	}, []);

	//prettier-ignore
	const onHandleSortBy = useCallback((id, order) => {

		if(order) {
			setSortBy({ id, order });
		}
		else {
			setSortBy({});
		}

		props.onHandleSort(id, order);
	}, [props]);

	const onHandleColumnChange = (v) => {
		if (props.columns.length - 2 === v.length) v.pop();

		setHiddenColumns(v);
		setVisibleColumns(v);
	};

	//prettier-ignore
	const SortByIcon = useCallback((column) => {
		if (column.disableSortBy) return null;

		if (sortBy.id === column.id) {
			if (sortBy.order === "asc") {
				return <img className={sortbyClassName(true, false)} src={sortbyIcon} alt="desc" onClick={() => onHandleSortBy(column.id, "")} />;
			}
			else {
				return <img className={sortbyClassName(false, false)} src={sortbyIcon} alt="asc" onClick={() => onHandleSortBy(column.id, "asc")} />;
			}
		} else {
			return <img className={sortbyClassName(false, true)} src={sortbyIcon} alt="inactive-sortby" onClick={() => onHandleSortBy(column.id, "desc")}/>;
		}
	}, [sortbyClassName, sortBy, onHandleSortBy]);

	//prettier-ignore
	useImperativeHandle(ref, () => ({
		onHandleResetSortBy: onHandleResetSortBy,
		onHandleGetSelectedRows: () => ({ selectedFlatRows, isAllRowsSelected })
	}));

	return (
		<div className="app-table">
			{!props.hideColumnSelect && (
				<div className="app-table__filter-column">
					<AppMultiSelectInput name="filter" placeholder="Filter Column" value={visibleColumns} options={visibleColumnOptions} onChange={onHandleColumnChange} />
				</div>
			)}

			<div className="app-table__container">
				<table className="table" {...getTableProps()} cellSpacing="0" cellPadding="0">
					<thead className="table__thead">
						{headerGroups.map((headerGroup) => (
							<tr className="table__tr" {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									const isActionColumn = column.id === "*";
									const isIndexingColumn = column.id === "index";
									const isSelectionColumn = column.id === "selection";
									const classNameTh = classNames({ table__th: true, "table__th--action": isActionColumn, "table__th--indexing": isIndexingColumn, "table__th--selection": isSelectionColumn });

									return (
										<th className={classNameTh} {...column.getHeaderProps()}>
											{column.render("Header")}
											<SortByIcon {...column} />
										</th>
									);
								})}
							</tr>
						))}
					</thead>

					<tbody className="table__tbody" {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr className="table__tr" {...row.getRowProps()}>
									{row.cells.map((cell) => {
										const isActiveCell = cell.column.id === "*";
										const isIndexingColumn = cell.column.id === "index";
										const isSelectionColumn = cell.column.id === "selection";
										const classNameTd = classNames({ table__td: true, "table__td--action": isActiveCell, "table__td--indexing": isIndexingColumn, "table__td--selection": isSelectionColumn });

										//prettier-ignore
										return (
										<td className={classNameTd} {...cell.getCellProps()}>
											{cell.render("Cell")}
										</td>
									);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{props.pages && props.onHandlePagination && !!props.pages.items?.length && (
				<div className="app-table__footer">
					<p className="app-table__text">{`${props.pages.offset + 1}-${props.pages.offset > 0 ? props.pages.offset + props.pages.size : props.pages.size} of ${props.pages.total} pages`}</p>
					<div className="app-table__pagination">
						<p className="app-table__text">The page you’re on</p>

						<div className="app-table__page">
							<FormControl>
								<Select IconComponent={(icon) => <AppChevronIcon className="MuiSelect-icon" {...icon} />} label="page" id="table-select" name="page" value={props.pages.total ? props.pages.page : 1} onChange={props.onHandlePagination}>
									{totalPageItems.map((o, i) => /*prettier-ignore*/ <MenuItem value={i + 1} key={i}>{i + 1}</MenuItem>)}
								</Select>
							</FormControl>
						</div>

						<button className="app-table__arrow app-table__arrow--prev" data-ctrl="prev" disabled={!props.pages.prev} onClick={props.onHandlePagination}>
							<AppArrowIcon color={prevAvailableColor} />
						</button>
						<button className="app-table__arrow app-table__arrow--next" data-ctrl="next" disabled={!props.pages.next} onClick={props.onHandlePagination}>
							<AppArrowIcon color={nextAvailableColor} />
						</button>
					</div>
				</div>
			)}
		</div>
	);
});

AppTable.propTypes = {
	checker: PropTypes.bool,
	onHandleSort: PropTypes.func,
	onHandlePagination: PropTypes.func,
	pages: PropTypes.shape({
		prev: PropTypes.bool.isRequired,
		next: PropTypes.bool.isRequired,
		page: PropTypes.number.isRequired,
		items: PropTypes.array.isRequired,
		size: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired,
		offset: PropTypes.number.isRequired,
	}),
};

export default memo(AppTable);
