import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setProfile(state, action) {
			return Object.assign(state, action.payload);
		},
		resetProfile() {
			return initialState;
		},
	},
});

export const { setProfile, resetProfile } = profileSlice.actions;

export default profileSlice.reducer;
