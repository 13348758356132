import React, { memo } from "react";

const AppLogoutIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.63007 13.5251L3.57007 13.5251L3.57007 2.4751L6.63007 2.4751" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5.625 8.02979L11.86 8.02979" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10.175 5.02979L12.43 8.02978L10.175 11.0298" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppLogoutIcon);
