import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import { debounce } from "lodash";
import pathnames from "routes/pathnames";
import sanitizeObject from "common/sanitize-object";
import { formatDatePattern, formatDateTime } from "common/calendar";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppTable, { indexing, AppTableCell, AppTableEditIcon } from "components/app-table";
import addIcon from "assets/images/add-icon.svg";

const PageCampaigns = (props) => {
	const navigate = useNavigate();
	const tableRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSetSearchParams = useRef(setSearchParams);
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
	const paramsRef = useRef({ page: parseInt(searchParams.get("page")) || 1, status: searchParams.get("status") || "", title: searchParams.get("title") || "", sort: searchParams.get("sort") || defaultSortBy });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const createAccessible = restrictedActions(ROLES.CAMPAIGNS, ROLES.CREATE_CAMPAIGNS);
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.title && !paramsRef.current.status, [data.status]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };

			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.campaign.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable,
			}));
		}
	}, []);

	const onHandleCreate = () => {
		navigate(pathnames.marketingInitiatives.campaign + COMMON.APP_STATUS.NEW);
	};

	//prettier-ignore
	const onHandleEdit = useCallback((id) => {
		navigate(pathnames.marketingInitiatives.campaign + id);
	}, [navigate]);

	const onHandleSearch = (event) => {
		const value = event.target.value;
		paramsRef.current.title = value;
		paramsRef.current.status = "";
		paramsRef.current.sort = "";
		paramsRef.current.page = 1;

		setSearchParams({ title: value });
		tableRef.current.onHandleResetSortBy();
		onHandleGetList();
	};

	const onHandleClearSearch = (event) => {
		paramsRef.current.title = "";
		paramsRef.current.status = "";
		paramsRef.current.sort = defaultSortBy;
		paramsRef.current.page = 1;

		setSearchParams("");
		tableRef.current.onHandleResetSortBy();
		onHandleGetList();
	};

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon onClick={() => onHandleEdit(row.original.id)} />; 
	}, [onHandleEdit]);

	const onHandleSort = (id, order) => {
		paramsRef.current.sort = order ? id + "," + order : defaultSortBy;

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Advertisement Banner Title",
			accessor: "title",
			disableSortBy: true,
		},
		{
			Header: "Effective Date",
			accessor: "startDate",
			disableSortBy: true,
			Cell: ({ row }) => <AppTableCell left value={formatDatePattern(row.original.startDate)} />,
		},
		{
			Header: "Expiry Date",
			accessor: "endDate",
			disableSortBy: true,
			Cell: ({ row }) => <AppTableCell left value={formatDatePattern(row.original.endDate)} />,
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: false,
			Cell: ({ row }) => <AppStatus status={row.original.status} />,
		},
		{
			Header: "Last Update by",
			accessor: "lastModifiedDate",
			disableSortBy: false,
			Cell: ({ row }) => <AppTableCell left value={`${row.original.lastModifiedByName}\n${formatDateTime(new Date(row.original.lastModifiedDate))}`} />,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.CAMPAIGN.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-campaigns">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={!createAccessible} onClick={onHandleCreate} />}

			{!isEmptyState && (
				<div className="campaigns">
					<AppSearchInput hideButton={!createAccessible} buttonLabel="Add New" buttonIcon={addIcon} onButtonClick={onHandleCreate} defaultValue={paramsRef.current.title} onChange={onHandleDebounceSearch} onClear={onHandleClearSearch} />

					{paramsRef.current.title && (
						<div className="campaigns__results">
							<p className="campaigns__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
				</div>
			)}
		</div>
	);
};

export default PageCampaigns;
