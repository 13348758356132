import React, { memo } from "react";

const AppReferralIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="affialiate-profile-maintenance-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.62664 3.9592C8.98664 3.21254 9.79998 2.76587 10.6466 2.91254C11.6733 3.08587 12.3666 4.07254 12.1866 5.11254C12.0266 6.05254 11.2066 6.71254 10.2866 6.6992" stroke={color} strokeMiterlimit="10" strokeLinecap="round" />
			<path d="M10.1467 6.72555C10.3067 6.69888 10.4733 6.68555 10.64 6.68555C12.3133 6.68555 13.6733 8.05221 13.6733 9.74555" stroke={color} strokeMiterlimit="10" strokeLinecap="round" />
			<path d="M2.33349 11.8599C2.91349 10.2599 4.44682 9.11328 6.24682 9.11328C6.90015 9.11328 7.52015 9.26661 8.07349 9.53328" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M6.24675 9.10708C4.86236 9.10708 3.74009 7.98481 3.74009 6.60042C3.74009 5.21602 4.86236 4.09375 6.24675 4.09375C7.63115 4.09375 8.75342 5.21602 8.75342 6.60042C8.75342 7.98481 7.63115 9.10708 6.24675 9.10708Z"
				stroke={color}
				strokeLinejoin="round"
			/>
			<path d="M10.0201 10.5268L8.63342 10.5268L10.6934 8.4668L12.7534 10.5268L11.4934 10.5268C11.4934 10.5268 11.3268 12.6668 8.84009 13.1135C8.84009 13.1135 10.1068 12.1335 10.0201 10.5268Z" stroke={color} strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppReferralIcon);
