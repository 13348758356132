import React, { useMemo, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import ERRORS from "common/errors";
import api from "services/api";
import ROLES from "common/roles";
import { formatDatePattern } from "common/calendar";
import { promptAlertMessage } from "store/slices/alert";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import converReadableFileSize from "common/convert-readable-file-size";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

const PageAmendmentRequest = (props) => {
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const updateAccessible = restrictedActions(ROLES.AMENDMENT_REQUEST, ROLES.UPDATE_AMENDMENT_REQUEST);
	/* prettier-ignore */
	const initialValues = useMemo(() => ({ remarks: "", status: "" }), []);
	/* prettier-ignore */
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED),
			remarks: yup.string().when(["status"], {
				is: (status) => status === COMMON.STATUS_ID.REJECTED,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});
	const memoSetValues = useMemo(() => formik.setValues, [formik.setValues]);
	const isRejected = useMemo(() => formik.values.status === COMMON.STATUS_ID.REJECTED || !!formik.values.remarks, [formik.values.status, formik.values.remarks]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const isDisabled = useMemo(() => !updateAccessible || formik.isSubmitting, [updateAccessible, formik.isSubmitting]);

	const summary = useMemo(() => {
		const info = [
			{ label: "Request ID", value: formik.values.refNo },
			{ label: "Business Reg. No.", value: formik.values.businessRegNo },
			{ label: "Policy No.", value: formik.values.policyNo },
			{ label: "Employee Name", value: formik.values.name },
			{ label: "Employee ID", value: formik.values.employeeId },
			{ label: "Insurance Provider", value: formik.values.insuranceProviderName },
		];

		return info;
	}, [formik.values]);

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = { id, status: values.status, remarks: values.remarks };

			await api.post.amendmentRequest.update(payload);

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptAlertMessage({ message: "Amendment request has been updated" }));
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	useEffect(() => {
		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.amendmentRequest.amendmentRequest(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) memoSetValues({ ...response, remarks: response.remarks || "" });
		};

		if (id) onHandleGetDetails();
	}, [id, memoSetValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.AMENDMENT_REQUEST.AMENDMENT_REQUEST);
		};
	}, [cancelRequest]);

	const AmendmentRequestTable = useCallback((obj) => {
		return (
			<div className="amendment-request__table">
				<div className="amendment-request__table-header">
					<div className="amendment-request__table-header-cell">
						<p className="amendment-request__table-title"></p>
					</div>
					<div className="amendment-request__table-header-cell">
						<p className="amendment-request__table-title">Old</p>
					</div>
					<div className="amendment-request__table-header-cell">
						<p className="amendment-request__table-title">New</p>
					</div>
				</div>

				{obj.amendmentValues?.beforeValues?.name && (
					<div className="amendment-request__table-body">
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">Full Name</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">{obj.amendmentValues.beforeValues.name}</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-value">{obj.amendmentValues.afterValues.name}</p>
						</div>
					</div>
				)}

				{obj.amendmentValues?.beforeValues?.identificationType && (
					<div className="amendment-request__table-body">
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">Identification Type.</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">{obj.amendmentValues.beforeValues.identificationType}</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-value">{obj.amendmentValues.afterValues.identificationType}</p>
						</div>
					</div>
				)}

				{(obj.amendmentValues?.beforeValues?.nric || obj.amendmentValues?.beforeValues?.passort) && (
					<div className="amendment-request__table-body">
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">NRIC No./Passport No.</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">{obj.amendmentValues.beforeValues.nric || obj.amendmentValues.beforeValues.passport}</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-value">{obj.amendmentValues.afterValues.nric || obj.amendmentValues.afterValues.passport}</p>
						</div>
					</div>
				)}

				{obj.amendmentValues?.beforeValues?.nationality && (
					<div className="amendment-request__table-body">
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">Nationality</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">{obj.amendmentValues.beforeValues.nationality}</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-value">{obj.amendmentValues.afterValues.nationality}</p>
						</div>
					</div>
				)}

				{obj.amendmentValues?.beforeValues?.dateOfBirth && (
					<div className="amendment-request__table-body">
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">Date of Birth</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">{formatDatePattern(obj.amendmentValues.beforeValues.dateOfBirth)}</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-value">{formatDatePattern(obj.amendmentValues.afterValues.dateOfBirth)}</p>
						</div>
					</div>
				)}
			</div>
		);
	}, []);

	const DocumentsTable = useCallback((obj) => {
		if (!obj.documents) return null;

		return obj.documents.map((o, i) => {
			return (
				<div className="amendment-request__table" key={i}>
					<div className="amendment-request__table-header">
						<div className="amendment-request__table-header-cell">
							<p className="amendment-request__table-title">File Name</p>
						</div>
						<div className="amendment-request__table-header-cell">
							<p className="amendment-request__table-title">Date</p>
						</div>
						<div className="amendment-request__table-header-cell">
							<p className="amendment-request__table-file-size">File Size</p>
						</div>
					</div>

					<div className="amendment-request__table-body">
						<div className="amendment-request__table-body-cell">
							<a className="amendment-request__table-link" href={o.filePath} target="_blank" rel="noopener noreferrer">
								{o.fileName}
							</a>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-label">{formatDatePattern(obj.createdDate)}</p>
						</div>
						<div className="amendment-request__table-body-cell">
							<p className="amendment-request__table-file-size">{converReadableFileSize(o.fileSize, true)}</p>
						</div>
					</div>
				</div>
			);
		});
	}, []);

	return (
		<div className="page-amendment-request">
			<div className="amendment-request">
				<h1 className="amendment-request__name">{formik.values.enquiryNo}</h1>

				<form className="amendment-request__form" onSubmit={formik.handleSubmit}>
					<div className="amendment-request__container">
						<div className="amendment-request__box">
							<div className="amendment-request__wrapper">
								<div className="amendment-request__box-body">
									{/* prettier-ignore */}
									<AppSelectInput required type="text" name="status" label="Status" placeholder="Please Select" options={COMMON.STATUS_DROPDOWNS.AMENDMENT_REQUEST} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} disabled={isDisabled} />
								</div>
							</div>
						</div>
					</div>

					<div className="amendment-request__container">
						<div className="amendment-request__box">
							<div className="amendment-request__wrapper">
								<h1 className="amendment-request__title">Amendment Request Summary</h1>

								<ul className="amendment-request__list">
									{summary.map((o, i) => {
										return (
											<li className="amendment-request__item" key={i}>
												<p className="amendment-request__label">{o.label}</p>
												<p className="amendment-request__value">{o.value || "-"}</p>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
					</div>

					<div className="amendment-request__container">
						<div className="amendment-request__box">
							<div className="amendment-request__wrapper">
								<h1 className="amendment-request__title">Amendment Details</h1>

								<AmendmentRequestTable amendmentValues={formik.values} />
							</div>
						</div>
					</div>

					<div className="amendment-request__container">
						<div className="amendment-request__box">
							<div className="amendment-request__wrapper">
								<h1 className="amendment-request__title">Uploaded Documents</h1>

								<DocumentsTable documents={formik.values.uploadedDocs} createdDate={formik.values.createdDate} />
							</div>
						</div>
					</div>

					{isRejected && (
						<div className="amendment-request__container">
							<div className="amendment-request__box">
								<div className="amendment-request__wrapper">
									{/*prettier-ignore*/}
									<AppInput required multiline maxLength={5000} type="text" name="remarks" label="Remarks" placeholder="e.g.: Reject Reason" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} disabled={isDisabled} onChange={formik.handleChange} />
								</div>
							</div>
						</div>
					)}

					<div className="amendment-request__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" disabled={isDisabled} label="Save" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageAmendmentRequest;
