import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import api from "services/api";
import ERRORS from "common/errors";
import serveRequestErrors from "common/serve-request-errors";
import getReportTypeListing from "services/get-report-type-listing";
import { CALENDAR_FORMAT, isDateBeforeToday } from "common/calendar";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppCalendarInput from "components/app-calendar-input";

const PageReporting = (props) => {
	const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;
	const navigate = useNavigate();
	const [downloadProgress, setDownloadProgress] = useState(0);
	const initialValues = useMemo(() => ({ reportType: "", startDate: "", endDate: "" }), []);
	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			reportType: yup.string().required(ERRORS.REQUIRED),
			startDate: yup
				.string()
				.required(ERRORS.REQUIRED)
				.test("beforeEndDate", ERRORS.START_DATE, function (value) {
					return isDateBeforeToday(value, this.parent.endDate);
				}),
			endDate: yup
				.string()
				.required(ERRORS.REQUIRED)
				.test("afterStartDate", ERRORS.END_DATE, function (value) {
					return isDateBeforeToday(this.parent.startDate || new Date(), value);
				}),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleSubmit = async (values) => {
		let fileName = "";
		let response = null;

		try {
			const onDownloadProgress = (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				setDownloadProgress(percentCompleted);
			};

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("attachment; filename=")?.[1]?.split('"')?.[1];
	
				if (fileName) return data;
	
				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));
					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			const payload = {
				endDate: values.endDate,
				startDate: values.startDate,
				reportType: values.reportType,
			};

			response = await api.get.reporting.generate(payload, { onDownloadProgress, transformResponse });
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				setDownloadProgress(0);
				formik.setSubmitting(false);
			}, 1000);
		}
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	return (
		<div className="page-reporting">
			<div className="reporting">
				<h1 className="reporting__title">Generate Report</h1>

				<form className="reporting__form" onSubmit={formik.handleSubmit}>
					<div className="reporting__container">
						<div className="reporting__box">
							<div className="reporting__wrapper">
								<div className="reporting__box-body">
									{/* prettier-ignore */}
									<AppSelectInput searchable={false} required type="text" name="reportType" label="Report Type" placeholder="Please Select" loadOptions={getReportTypeListing} value={formik.values.reportType} error={formik.errors.reportType} touched={formik.touched.reportType} disabled={formik.isSubmitting} onChange={formik.handleChange} />
								</div>

								<div className="reporting__box-body">
									{/* prettier-ignore */}
									<AppCalendarInput required name="startDate" label="Start Date" placeholder="01 Jan 2023" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />

									{/* prettier-ignore */}
									<AppCalendarInput required name="endDate" label="End Date" placeholder="01 Dec 2023" maxDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} disabled={formik.isSubmitting} onChange={formik.setFieldValue} />
								</div>
							</div>
						</div>

						<div className="reporting__progress-bar" style={{ width: `${downloadProgress}%` }} />
					</div>

					<div className="reporting__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" disabled={formik.isSubmitting} label="Generate" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageReporting;
