import React, { memo } from "react";

const AppAuditIcon = ({ color = "#ffffff", ...props }) => {
	return (
		<svg {...props} className="audit-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.4199 4.14648L11.2933 4.14648C11.6466 4.14648 11.9333 4.43315 11.9333 4.78648L11.9333 7.37315" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M11.9335 9.79332L11.9335 12.7C11.9335 13.0533 11.6468 13.34 11.2935 13.34L4.30016 13.34C3.94682 13.34 3.66016 13.0533 3.66016 12.7L3.66016 4.79332C3.66016 4.43999 3.94682 4.15332 4.30016 4.15332L5.38682 4.15332"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.36645 3.48683L8.82645 3.48683C8.82645 3.44683 8.82645 3.4135 8.82645 3.3735C8.82645 2.80016 8.35979 2.3335 7.78646 2.3335C7.21312 2.3335 6.74645 2.80016 6.74645 3.3735C6.74645 3.4135 6.74645 3.4535 6.74645 3.48683L6.20646 3.48683C5.74646 3.48683 5.36645 3.86016 5.36645 4.32683L5.36645 4.98016L10.1798 4.98016L10.1798 4.32683C10.1798 3.86683 9.80645 3.48683 9.33979 3.48683L9.36645 3.48683Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M5.62671 7.77344L9.96671 7.77344" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M5.62671 9.79346L8.36671 9.79346" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default memo(AppAuditIcon);
