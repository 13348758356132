const validateImageDimension = (dimension, file) => {
	let reader = new FileReader();

	reader.readAsDataURL(file);

	return new Promise((resolve) => {
		reader.onload = function (e) {
			const image = new Image();
			image.src = e.target.result;

			image.onload = function () {
				const height = this.height;
				const width = this.width;

				if (height !== dimension.height && width !== dimension.width) {
					resolve(false);
				} else {
					resolve(true);
				}
			};
		};
	});
};

export default validateImageDimension;
