import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import AppTabs from "components/app-tabs";
import AppEndorsementDebitNoteTable from "components/pages/audit-log/app-endorsement-debit-note-table";
import AppEndorsementCreditNoteTable from "components/pages/audit-log/app-endorsement-credit-note-table";

const TAB = {
	DEBIT_NOTE: "DEBIT_NOTE",
	CREDIT_NOTE: "CREDIT_NOTE",
};

const tabs = [
	{ label: "Debit Note", value: TAB.DEBIT_NOTE },
	{ label: "Credit Note", value: TAB.CREDIT_NOTE },
];

const PageEndorsement = (props) => {
	const [searchParams] = useSearchParams();
	const [tab, setTab] = useState(searchParams.get("tab") || TAB.DEBIT_NOTE);
	const isDebitNoteTab = useMemo(() => tab === TAB.DEBIT_NOTE, [tab]);
	const isCreditNoteTab = useMemo(() => tab === TAB.CREDIT_NOTE, [tab]);
	const onHandleCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleTabChange = (currentTab) => {
		setTab(currentTab);
	};

	return (
		<div className="page-endorsement">
			<div className="endorsement">
				<AppTabs value={tab} tabs={tabs} onChange={onHandleTabChange} />

				{isDebitNoteTab && <AppEndorsementDebitNoteTable onHandleCancelRequest={onHandleCancelRequest} />}

				{isCreditNoteTab && <AppEndorsementCreditNoteTable onHandleCancelRequest={onHandleCancelRequest} />}
			</div>
		</div>
	);
};

export default PageEndorsement;
