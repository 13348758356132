import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";

import COMMON from "common";
import api from "services/api";
import { debounce } from "lodash";
import classNames from "common/class-names";
import { formatDateTime } from "common/calendar";
import downloadFiles from "common/download-files";
import eclipseString from "common/eclipse-string";
import serveRequestErrors from "common/serve-request-errors";
import AppInput from "components/app-input";
import AppStatus from "components/app-status";
import AppCloseIcon from "components/icons/app-close-icon";
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppEndConversationModal from "components/pages/customer-support/app-end-conversation-modal";
import searchIcon from "assets/images/search-icon.svg";
import fileIcon from "assets/images/pages/customer-support/file-icon.svg";
import attachementIcon from "assets/images/pages/customer-support/attachment-icon.svg";

const PageContactUsMessage = (props) => {
	const inputRef = useRef();
	const messagesRef = useRef();
	const messagesListRef = useRef();
	const messageContentRef = useRef();
	const endConversationRef = useRef();
	const [message, setMessage] = useState({});
	const [inputKey, setInputKey] = useState("");
	const paramsRef = useRef({ page: 1, keyword: "" });
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [], keyword: paramsRef.current.keyword });
	const isEndConversation = useMemo(() => COMMON.STATUS_ID.CONVERSATION_ENDED === message.status, [message]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const onHandleGetList = useCallback(async () => {
		const isPageInit = paramsRef.current.page === 1;
		let response = null;

		if (isPageInit) setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [], keyword: paramsRef.current.keyword });

		try {
			const payload = {
				size: 10,
				page: paramsRef.current.page - 1,
				keyword: paramsRef.current.keyword,
			};
			response = await api.get.contactUs.list(payload);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				prev: !response.first,
				next: !response.last,
				total: response.totalPages,
				page: paramsRef.current.page,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				items: isPageInit ? response.content : [...prev.items, ...response.content],
			}));
		}
	}, []);

	const onHandleMessage = async (obj, clearMessage, toggleViews = true) => {
		let response = null;

		obj.adminIsRead = COMMON.STATUS_ID.READ;

		try {
			if (clearMessage) setMessage({});

			response = await api.get.contactUs.message(obj.id);
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setMessage(response);
			setTimeout(() => {
				messagesRef.current.scrollTo({ top: messagesRef.current.scrollHeight, left: 0, behavior: "smooth" });
			}, 100);

			if (toggleViews) onHandleBackToInbox();
		}
	};

	const onHandleBackToInbox = () => {
		const inbox = document.querySelector(".messages__inbox");
		const content = document.querySelector(".messages__content");

		if (inbox && content) {
			inbox.classList.toggle("messages__inbox--hide");
			content.classList.toggle("messages__content--show");
		}
	};

	//prettier-ignore
	const onHandleClearSearch =() => {
		paramsRef.current.keyword = "";
		paramsRef.current.page = 1;
		onHandleGetList();
	};

	//prettier-ignore
	const onHandleSearch =(event) => {
		const value = event.target.value;
		paramsRef.current.keyword = value;
		paramsRef.current.page = 1;
		onHandleGetList();
	};

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleEndConversation = async () => {
		endConversationRef.current.onHandleShow(message.id);
	};

	const onHandleSendMessage = async () => {
		try {
			const payload = { id: message.id, comment: inputKey };
			await api.post.contactUs.sendMessage(payload);
			onHandleMessage({ id: message.id }, false, false);
			setInputKey("");
		} catch (error) {
			serveRequestErrors(error);
		}
	};

	const onHandleKeyDown = (event) => {
		const value = event.target.value;
		const enterKey = event.keyCode === 13;
		if (enterKey && value) onHandleSendMessage();
	};

	const onHandleChange = (event) => {
		const value = event.target.value;
		setInputKey(value);
	};

	const onHandleViewMessage = (event) => {
		const target = event.target;
		target.parentNode.firstChild.classList.toggle("history__text--show");
		target.parentNode.parentNode.lastChild.classList.toggle("history__attachment--show");
		target.innerHTML = target.innerHTML === "View less" ? "View more" : "View less";
	};

	useEffect(() => {
		const multiLineOverflows = (element) => {
			if (!element) return false;

			return element.scrollHeight > element.clientHeight + 1;
		};

		const onHandleCommentText = () => {
			/* Apply see more button if multiline overflows */
			const elements = document.querySelectorAll(".history__text");

			elements.forEach((o) => {
				const isMultiLineOverflows = multiLineOverflows(o);
				const nextElementSibling = o.nextElementSibling;
				const isValidSeeMoreButton = nextElementSibling.className === "history__expand";
				const attachementElement = o.parentNode.nextElementSibling?.className === "history__attachment";

				if (attachementElement) return;

				if (!isValidSeeMoreButton || attachementElement) return;

				if (isMultiLineOverflows) {
					nextElementSibling.style.display = "block";
				} else {
					nextElementSibling.style.display = "none";
				}
			});
		};

		const debouncedCheck = debounce(onHandleCommentText, 50);

		if (message?.comments) onHandleCommentText();

		window.addEventListener("resize", debouncedCheck);

		return () => {
			window.removeEventListener("resize", debouncedCheck);
		};
	}, [message?.comments]);

	useEffect(() => {
		const target = messagesListRef.current;

		const onHandleScroll = (event) => {
			const scrollHeight = event.target.scrollHeight;
			const offsetHeight = event.target.offsetHeight;
			const scrollTop = event.target.scrollTop;

			if (scrollHeight === offsetHeight + scrollTop) {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
				onHandleGetList();
			}
		};

		target.addEventListener("scroll", onHandleScroll);

		return () => {
			target.removeEventListener("scroll", onHandleScroll);
		};
	}, [onHandleGetList, data.total]);

	useEffect(() => {
		const input = inputRef.current;

		input.addEventListener("input", autoResize, false);

		function autoResize() {
			const scrollHeight = this.scrollHeight;
			this.style.height = "auto";

			if (scrollHeight > 300) {
				this.style.height = "300px";
			} else {
				this.style.height = this.scrollHeight + "px";
			}
		}

		return () => {
			input.removeEventListener("input", autoResize);
		};
	}, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.CONTACT_US.LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-contact-us-messages">
			<div className="contact-us-messages">
				<div className="messages">
					<div className="messages__container">
						<div className="messages__inbox">
							<div className="search-input">
								<AppInput type="text" name="search" placeholder="Search" icon={searchIcon} onChange={onHandleDebounceSearch} onClear={onHandleClearSearch} onBlur={() => {}} />
							</div>

							<ul ref={messagesListRef} className="list">
								{data.items.map((o, i) => {
									const isRead = o.adminIsRead === COMMON.STATUS_ID.READ;
									const status = o.status === COMMON.STATUS_ID.ACTIVE ? COMMON.STATUS_ID.PENDING : o.status;
									const titleClassName = classNames({ list__title: true, "list__title--new": !isRead });
									const itemClassName = classNames({ list__item: true, "list__item--new": !isRead });

									return (
										<li className={itemClassName} key={i} onClick={() => onHandleMessage(o, true)}>
											<div className="list__header">
												<div className="list__wrapper">
													<p className="list__name">{o.createdByName}</p>
													<p className="list__enquiry-no">{o.inquiryNo}</p>
												</div>

												<p className="messages__date">
													{o.files && <img className="list__attachement" src={attachementIcon} alt="attachement" />}
													<span className="messages__date">{formatDateTime(new Date(o.createdDate))}</span>
												</p>
											</div>
											<div className="list__body">
												<div className="list__wrapper">
													<p className={titleClassName}>{o.subject}</p>
													<p className="list__text">{eclipseString(o.comments?.[o.comments?.length - 1]?.comment, 90)}</p>
												</div>
												<div className="list__wrapper">
													<AppStatus status={status} />
												</div>
											</div>
										</li>
									);
								})}
							</ul>
						</div>

						<div ref={messageContentRef} className="messages__content">
							<div className="messages__subject-header">
								<button className="messages__back-button" onClick={onHandleBackToInbox}>
									<AppChevronIcon color="#0245a9" />
								</button>

								<p className="messages__subject">{message.subject}</p>

								{message.id && !isEndConversation && (
									<button className="messages__end-session" onClick={onHandleEndConversation}>
										End Conversation
										<span className="messages__end-session-icon">
											<AppCloseIcon color="#F64444" />
										</span>
									</button>
								)}
							</div>

							<div ref={messagesRef} className="messages__body">
								{message?.comments?.map((a, i) => {
									const creator = a.createdBy === message.createdBy;
									const firstMessage = i === 0;

									return (
										<div className="history history--collapse" key={i}>
											<div className="history__header">
												<div className="history__content">
													<p className="history__creator">{creator ? a.createdByName : "You"}</p>
													<p className="history__email">{message.email}</p>
												</div>
												<p className="messages__date">{formatDateTime(new Date(a.createdDate))}</p>
											</div>

											<div className="history__comment">
												{/* prettier-ignore */}
												<p className="history__text">{a.comment}</p>

												{/* prettier-ignore */}
												<p className="history__expand" onClick={onHandleViewMessage}>View more</p>
											</div>

											{firstMessage && (
												<div className="history__attachment">
													<div className="history__attachment-header">
														<p className="history__title">{message?.files?.length || 0} Attachment</p>
														{/* prettier-ignore */}
														<button type="button" className="history__download" disabled={!message?.files?.length} onClick={() => downloadFiles(message.files)}>Download All</button>
													</div>

													{message?.files?.map((b, j) => {
														return (
															<div className="history__attachment-doc" key={j}>
																<img className="history__file-icon" src={fileIcon} alt="file" />

																<p className="history__file">
																	{/* prettier-ignore */}
																	<a href={b.url} target="_blank" rel="noreferrer">{b.fileName}</a>
																</p>
															</div>
														);
													})}
												</div>
											)}
										</div>
									);
								})}
							</div>

							{!isEndConversation && (
								<div className="input-bar">
									<div className="input-bar__container">
										<textarea ref={inputRef} className="input-bar__textarea" rows="1" disabled={!message.id} value={inputKey} placeholder="Write something..." onChange={onHandleChange} onKeyDown={onHandleKeyDown} />

										{/* prettier-ignore */}
										<button className="input-bar__button" disabled={!inputKey.length} onClick={onHandleSendMessage}>Reply</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<AppEndConversationModal ref={endConversationRef} onHandleMessage={onHandleMessage} onHandleSetData={setData} />
		</div>
	);
};

export default PageContactUsMessage;
