const formatStringPattern = (value, pattern = "", mask = "") => {
	if (!value) return "";

	const strippedValue = value.replace(/[^0-9]/g, "");
	const chars = strippedValue.split("");
	let count = 0;

	let formatted = "";

	for (let i = 0; i < pattern.length; i++) {
		const c = pattern[i];

		if (chars[count]) {
			if (/\*/.test(c)) {
				formatted += chars[count];
				count++;
			} else {
				formatted += c;
			}
		} else if (mask) {
			if (mask.split("")[i]) formatted += mask.split("")[i];
		}
	}
	return formatted;
};

export default formatStringPattern;
