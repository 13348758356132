export const tempDashboard = [
	{
		id: "MSME",
		label: "MSME",
		color: "hsla(184, 71%, 57%, 1)",
		data: [
			{
				x: "JAN",
				y: 227,
			},
			{
				x: "FEB",
				y: 154,
			},
			{
				x: "MAR",
				y: 215,
			},
			{
				x: "APR",
				y: 230,
			},
			{
				x: "MAY",
				y: 57,
			},
			{
				x: "JUN",
				y: 156,
			},
			{
				x: "JUL",
				y: 136,
			},
			{
				x: "AUG",
				y: 237,
			},
			{
				x: "SEP",
				y: 45,
			},
			{
				x: "OCT",
				y: 158,
			},
			{
				x: "NOV",
				y: 294,
			},
			{
				x: "DEC",
				y: 99,
			},
		],
	},
	{
		id: "Employee Benefits",
		label: "Employee Benefits",
		color: "hsla(236, 82%, 65%, 1)",
		data: [
			{
				x: "JAN",
				y: 0,
			},
			{
				x: "FEB",
				y: 23,
			},
			{
				x: "MAR",
				y: 43,
			},
			{
				x: "APR",
				y: 65,
			},
			{
				x: "MAY",
				y: 57,
			},
			{
				x: "JUN",
				y: 256,
			},
			{
				x: "JUL",
				y: 43,
			},
			{
				x: "AUG",
				y: 37,
			},
			{
				x: "SEP",
				y: 112,
			},
			{
				x: "OCT",
				y: 128,
			},
			{
				x: "NOV",
				y: 24,
			},
			{
				x: "DEC",
				y: 123,
			},
		],
	},
];
