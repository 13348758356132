import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import COMMON from "common";
import api from "services/api";
import { formatDatePattern } from "common/calendar";
import sanitizeObject from "common/sanitize-object";
import queryParamsEntries from "common/query-params-entries";
import serveRequestErrors from "common/serve-request-errors";
import formatStringPattern from "common/format-string-pattern";
import AppTabs from "components/app-tabs";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppEmptyState from "components/app-empty-state";
import AppSearchInput from "components/app-search-input";
import AppClaimModal from "components/pages/insurance-management/app-claim-modal";
import AppTable, { indexing, AppTableCell, AppTableEditIcon } from "components/app-table";
import AppExportReportModal, { TYPES } from "components/pages/insurance-management/app-export-report-modal";
import exportIcon from "assets/images/export-icon.svg";
import previewIcon from "assets/images/preview-icon.svg";

const PageClaims = (props) => {
	const tableRef = useRef();
	const claimRef = useRef();
	const searchInputRef = useRef();
	const exportReportModalRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultSortBy = useMemo(() => "lastModifiedDate,desc", []);
	const paramsRef = useRef({
		page: parseInt(searchParams.get("page")) || 1,
		sort: searchParams.get("sort") || defaultSortBy,
		policyNo: searchParams.get("policyNo") || "",
		refNo: searchParams.get("refNo") || "",
		name: searchParams.get("name") || "",
	});
	const [data, setData] = useState({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const tabsNav = useMemo(() => [{ label: "Employee Benefit", value: "EMPLOYEE_BENEFIT" }], []);
	const initialValues = useMemo(() => ({ companyName: "", name: "", refNo: "" }), []);
	const memoSetSearchParams = useRef(setSearchParams);
	//prettier-ignore
	const isEmptyState = useMemo(() => COMMON.TABLE_REQUEST_STATUS.INSTANCE === data.status && !paramsRef.current.name && !paramsRef.current.refNo && !paramsRef.current.policyNo, [data.status]);
	const isSearchState = useCallback(() => {
		const { tab, page, sort, ...res } = paramsRef.current;
		return Object.values(res).some((o) => o);
	}, []);
	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmitSearch(values);
		},
	});

	const setValues = useMemo(() => formik.setValues, [formik]);

	const advanceSearchValues = useMemo(() => {
		const params = queryParamsEntries(searchParams);
		let values = { name: params.name, refNo: params.refNo, policyNo: params.policyNo };

		return values;
	}, [searchParams]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		setData({ page: paramsRef.current.page, size: 10, total: 0, offset: 0, totalElements: 0, prev: false, next: false, items: [] });

		try {
			const payload = { ...paramsRef.current, size: 10 };
			
			memoSetSearchParams.current(sanitizeObject(payload));

			payload.page = paramsRef.current.page - 1;

			response = await api.get.claims.list(sanitizeObject(payload));
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			setData((prev) => ({
				...prev,
				page: paramsRef.current.page,
				prev: !response.first,
				next: !response.last,
				items: response.content,
				total: response.totalPages,
				totalElements: response.totalElements,
				offset: response?.pageable?.offset || 0,
				status: response?.pageable
			}));
		}
	}, []);

	const onHandlePreview = useCallback((id) => {
		claimRef.current.onHandleShow(id);
	}, []);

	const onHandleExportReport = useCallback(() => {
		exportReportModalRef.current.onHandleShow();
	}, []);

	const onHandleSubmitSearch = (values) => {
		searchInputRef.current.onhandleCloseAdvanceSearch();

		paramsRef.current = { ...paramsRef.current, page: 1, ...values };

		onHandleGetList();
	};

	const onHandleRemoveField = (field) => {
		formik.setFieldValue(field, "");

		paramsRef.current = { ...paramsRef.current, page: 1, [field]: "" };

		onHandleGetList();
	};

	const onHandleResetSearch = () => {
		formik.setValues(formik.initialValues);

		paramsRef.current = { page: 1, sort: "", ...formik.initialValues };

		onHandleGetList();
	};

	const onHandlePagination = (event) => {
		const control = event.currentTarget?.getAttribute("data-ctrl");

		if (control) {
			if (control === "prev") {
				if (paramsRef.current.page <= 1) return;
				paramsRef.current.page -= 1;
			} else {
				if (paramsRef.current.page >= data.total) return;
				paramsRef.current.page += 1;
			}
		} else {
			paramsRef.current.page = event.target.value;
		}

		onHandleGetList();
	};

	const onHandleSort = (id, order) => {
		switch (id) {
			case "policyNo":
				paramsRef.current.sort = order ? "p.policyNo," + order : "";
				break;
			default:
				paramsRef.current.sort = order ? id + "," + order : defaultSortBy;
				break;
		}

		paramsRef.current.page = 1;

		onHandleGetList();
	};

	//prettier-ignore
	const MenuCell = useCallback(({ row }) => {
		return <AppTableEditIcon icon={previewIcon} onClick={() => onHandlePreview(row.original.id)} />; 
	}, [onHandlePreview]);

	const SubmissionDateCell = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.createdDate))} />;
	}, []);

	const LastModifiedDate = useCallback(({ row }) => {
		return <AppTableCell left value={formatDatePattern(new Date(row.original.lastModifiedDate))} />;
	}, []);

	//prettier-ignore
	const CustomerIdCell = useCallback(({ row }) => {
		const value = row.original.passport ? row.original.passport : formatStringPattern(row.original.nric, COMMON.MASKING.NRIC);
		
		return <AppTableCell left value={value} />;
	}, []);

	//prettier-ignore
	const StatusCell = useCallback(({ row }) => {
		return <AppStatus status={row.original.status} />
	}, []);

	//prettier-ignore
	const columns = useMemo(() => [
		{
			Header: "#",
			id: "index",
			disableSortBy: true,
			accessor: (_row, i) => indexing(paramsRef.current.page, i),
		},
		{
			Header: "Claim Ref. No.",
			accessor: "refNo",
			disableSortBy: false,
		},
		{
			Header: "Policy No.",
			accessor: "policyNo",
			disableSortBy: false,
		},
		{
			Header: "Claimant Name",
			accessor: "name",
			disableSortBy: false,
		},
		{
			Header: "Claimant ID",
			accessor: "identity",
			disableSortBy: true,
            Cell: CustomerIdCell
		},
		{
			Header: "Submission Date",
			accessor: "createdDate",
			disableSortBy: false,
            Cell: SubmissionDateCell
		},
		{
			Header: "Last Updated Date",
			accessor: "lastModifiedDate",
			disableSortBy: false,
            Cell: LastModifiedDate
		},
		{
			Header: "Status",
			accessor: "status",
			disableSortBy: true,
			Cell: StatusCell,
		},
		{
			Header: "",
			accessor: "*",
			disableSortBy: true,
			Cell: MenuCell,
		},
	], [MenuCell, SubmissionDateCell, LastModifiedDate, CustomerIdCell, StatusCell]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const { page, sort, ...res } = paramsRef.current;

		setValues((prev) => ({ ...prev, ...res }));
	}, [setValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.POLICIES.EB_LIST);
		};
	}, [cancelRequest]);

	return (
		<div className="page-claims">
			{isEmptyState && <AppEmptyState title="No records found" description="You don’t have any records yet" disabledButton={true} />}

			{!isEmptyState && (
				<div className="claims">
					<AppTabs tabs={tabsNav} onChange={() => {}} />

					{/* prettier-ignore */}
					<AppSearchInput ref={searchInputRef} multiValues={advanceSearchValues} onRemoveField={onHandleRemoveField} buttonLabel="Export" buttonIcon={exportIcon} onButtonClick={onHandleExportReport}>
					<form className="app-advance-search-form" onSubmit={formik.handleSubmit}>
						<div className="advance-form">
							<div className="advance-form__inputs">
								<AppInput type="text" name="policyNo" label="Policy No." placeholder="Enter Policy No." value={formik.values.policyNo} onChange={formik.handleChange} />
								
								<AppInput type="text" name="name" label="Claimant Name" placeholder="Enter Claimant Name" value={formik.values.name} onChange={formik.handleChange} />
								
								<AppInput type="text" name="refNo" label="Claim Ref. No." placeholder="Enter Claim Ref. No." value={formik.values.refNo} onChange={formik.handleChange} />
							</div>

							<div className="advance-form__button-container">
								<AppButton type="button" label="Clear" outline onClick={onHandleResetSearch} />
								<AppButton type="submit" label="Search" />
							</div>
						</div>
					</form>
				</AppSearchInput>

					{isSearchState() && (
						<div className="claims__results">
							<p className="claims__text">{data.totalElements} results found</p>
						</div>
					)}

					<AppTable ref={tableRef} columns={columns} pages={data} onHandlePagination={onHandlePagination} onHandleSort={onHandleSort} />
				</div>
			)}

			<AppClaimModal ref={claimRef} />

			<AppExportReportModal ref={exportReportModalRef} type={TYPES.CLAIMS} />
		</div>
	);
};

export default PageClaims;
