import React, { useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import COMMON from "common";
import api from "services/api";
import ROLES from "common/roles";
import ERRORS from "common/errors";
import { promptAlertMessage } from "store/slices/alert";
import validateFileSize from "common/validate-file-size";
import restrictedActions from "common/restricted-actions";
import serveRequestErrors from "common/serve-request-errors";
import validateImageDimension from "common/validate-image-dimension";
import converReadableFileSize from "common/convert-readable-file-size";
import { formatDateTime, isDateBeforeToday, CALENDAR_FORMAT, getISOString } from "common/calendar";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectStatus from "components/app-select-status";
import AppCalendarInput from "components/app-calendar-input";
import uploadIcon from "assets/images/upload-icon.svg";
import removeIcon from "assets/images/remove-icon.svg";

const PageCampaign = (props) => {
	const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;
	let { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const uploadInputRef = useRef();
	const isCreate = useMemo(() => id === COMMON.APP_STATUS.NEW, [id]);
	const updateAccessible = restrictedActions(ROLES.CAMPAIGNS, isCreate ? ROLES.CREATE_CAMPAIGNS : ROLES.UPDATE_CAMPAIGNS);
	const initialEndDate = useMemo(() => getISOString("12/31/2099"), []);
	//prettier-ignore
	const initialValues = useMemo(() => ({ status: isCreate ? COMMON.STATUS_ID.ACTIVE : undefined, title: "", url: "", file: null, startDate: "", endDate: initialEndDate, uploadedFile: {}, deleleFileId: "", lastModifiedDate: "", lastModifiedByName: "" }), [isCreate, initialEndDate]);
	//prettier-ignore
	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED),
			title: yup.string().required(ERRORS.REQUIRED),
			startDate: yup.string().when(["status"], {
				is: (status) => status === COMMON.STATUS_ID.ACTIVE,
				then: () => yup.string().required(ERRORS.REQUIRED).test("dateIsExpired", ERRORS.START_DATE_EXPIRED, function (value) { return isDateBeforeToday(new Date(), value) }).test("beforeEndDate", ERRORS.START_DATE, function (value) { return isDateBeforeToday(value, this.parent.endDate) }),
				otherwise: () => yup.string().required(ERRORS.REQUIRED),
			}),
			endDate: yup.string().when(["status"], {
				is: (status) => status === COMMON.STATUS_ID.ACTIVE,
				then: () => yup.string().required(ERRORS.REQUIRED).test("afterStartDate", ERRORS.END_DATE, function (value) { return isDateBeforeToday(this.parent.startDate || new Date(), value) }),
				otherwise: () => yup.string().required(ERRORS.REQUIRED),
			}),
			url: yup.string().url().required(ERRORS.REQUIRED),
			deleleFileId: yup.string(),
			file: yup.mixed().when(["deleleFileId", "uploadedFile"], {
				is: (isFileDelete, isUploadedFile) => (!isFileDelete && !isUploadedFile.link) || isFileDelete,
				then: () => yup.mixed().required(ERRORS.REQUIRED).test("fileSize", ERRORS.FILE_SIZE, validateFileSize).test("fileDimension", ERRORS.CAMPAIGN_IMAGE_SIZE, (v) => validateImageDimension({ width: 1216, height: 480 }, v)),
				otherwise: () => yup.mixed().notRequired(),
			}),
			uploadedFile: yup.object(),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const memoSetValues = useMemo(() => formik.setValues, [formik]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const fileUploaded = useMemo(() => !!formik.values.file, [formik.values.file]);
	const deleleFileId = useMemo(() => !!formik.values.deleleFileId, [formik.values.deleleFileId]);
	const prevUploadedFile = useMemo(() => !!formik.values.uploadedFile?.link, [formik.values.uploadedFile]);
	const fileSize = useMemo(() => formik.values.file?.size || formik.values.uploadedFile?.fileSize, [formik.values]);
	const isDisabled = useMemo(() => !updateAccessible || formik.isSubmitting, [updateAccessible, formik.isSubmitting]);
	const title = useMemo(() => (isCreate ? "New Banner" : formik.values.title || "-"), [formik.values.title, isCreate]);

	const onHandleUploadFile = () => {
		uploadInputRef.current.click();
	};

	const onHandleChangeFile = (event) => {
		const file = event.target.files[0];
		formik.setFieldValue("file", file);
	};

	const onHandleRemoveFile = () => {
		if (!isCreate) formik.setFieldValue("deleleFileId", id);
		formik.setFieldValue("file", "");
	};

	const onHandleCancel = () => {
		navigate(-1);
	};

	const onHandleSubmit = async (values) => {
		let response = null;

		try {
			const payload = {
				status: values.status,
				title: values.title,
				description: values.description,
				hyperlink: values.url,
				startDate: values.startDate,
				endDate: values.endDate,
			};

			if (isCreate) {
				response = await api.post.campaign.create(payload);
			} else {
				payload.id = id;
				response = await api.post.campaign.update(payload);
			}
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}

		if (response) onHandleUploadImage(values, response?.id);
	};

	const onHandleUploadImage = async (values, campaign = id) => {
		const onHandleSucess = () => {
			dispatch(promptAlertMessage({ message: isCreate ? "Campaign has been created." : "Campaign has been updated." }));
			navigate(-1);
		};

		if (!values.file) return onHandleSucess();

		try {
			const formData = new FormData();
			formData.append("file", values.file);

			await api.post.campaign.uploadImage(campaign, formData);
			onHandleSucess();
		} catch (error) {
			serveRequestErrors(error);
			formik.setSubmitting(false);
		}
	};

	useEffect(() => {
		const isEdit = id !== COMMON.APP_STATUS.NEW;

		const onHandleGetDetails = async () => {
			let response = null;

			try {
				response = await api.get.campaign.campaign(id);
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				memoSetValues({
					file: "",
					deleleFileId: "",
					url: response.hyperlink,
					title: response.title,
					startDate: response.startDate,
					endDate: response.endDate,
					uploadedFile: {
						link: response.ossUrl,
						fileName: response.bannerFileName,
						fileSize: response.bannerFileSize,
					},
					lastModifiedByName: response.lastModifiedByName,
					lastModifiedDate: response.lastModifiedDate,
					status: response.status === COMMON.STATUS_ID.EXPIRED ? undefined : response.status,
				});
			}
		};

		if (isEdit) onHandleGetDetails();
	}, [id, memoSetValues]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.CAMPAIGN.CAMPAIGN);
		};
	}, [cancelRequest]);

	return (
		<div className="page-campaign">
			<div className="campaign">
				<h1 className="campaign__name">{title}</h1>
				{!isCreate && (
					<p className="campaign__date">
						Last updated by: {formik.values.lastModifiedByName}, {formatDateTime(new Date(formik.values.lastModifiedDate))}
					</p>
				)}

				<form className="campaign__form" onSubmit={formik.handleSubmit}>
					<div className="campaign__container">
						<div className="campaign__box">
							<div className="campaign__wrapper">
								<div className="campaign__box-body">
									<AppSelectStatus value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} disabled={isDisabled} onChange={formik.setFieldValue} />

									{/*prettier-ignore*/}
									<AppInput required type="text" name="title" label="Advertisement Banner Title" placeholder="Please enter Advertisement Banner Title" value={formik.values.title} disabled={isDisabled} error={formik.errors.title} touched={formik.touched.title} onChange={formik.handleChange} />

									{/*prettier-ignore*/}
									<AppInput required type="text" name="url" label="Hyperlink" placeholder="e.g. http://website.com/link" value={formik.values.url} disabled={isDisabled} error={formik.errors.url} touched={formik.touched.url} onChange={formik.handleChange} />

									{/*prettier-ignore*/}
									<AppCalendarInput required name="startDate" label="Effective Date" placeholder="01 Jan 2023" minDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.startDate} disabled={isDisabled} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} />

									{/*prettier-ignore*/}
									<AppCalendarInput required name="endDate" label="Expiry Date" placeholder="01/01/2099" minDate={new Date()} displayFormat={calendarDisplayFormat} value={formik.values.endDate} disabled={isDisabled} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.setFieldValue} />
								</div>
							</div>
						</div>
					</div>
					<div className="campaign__box">
						<div className="campaign__box-header">
							<p className="campaign__title">Upload</p>
							<p className="campaign__description">Please ensure that your image is in the correct format (jpg, png, 1216px x 480px) with file size not exceeding 5MB.</p>
						</div>

						<div className="campaign__wrapper campaign__wrapper--upload">
							<div className="campaign__box-body">
								<div className="upload-table">
									<div className="upload-table__header">
										<p className="upload-table__label">File Name</p>
										<p className="upload-table__label">Uploaded</p>
										<p className="upload-table__label">File Size</p>
									</div>
									<div className="upload-table__body">
										<div className="upload-table__values">
											Content Image<span>*</span>
										</div>

										{((!fileUploaded && deleleFileId) || (!prevUploadedFile && deleleFileId) || (isCreate && !fileUploaded) || (!fileUploaded && !prevUploadedFile)) && (
											<div className="upload-table__values">
												<AppButton type="button" label="Upload File" icon={uploadIcon} onClick={onHandleUploadFile} />
												<input type="file" name="file" accept="image/png, image/jpeg" hidden ref={uploadInputRef} onChange={onHandleChangeFile} />
											</div>
										)}

										{fileUploaded && (
											<div className="upload-table__values upload-table__values--uploaded">
												{formik.values.file.name}
												{!isDisabled && <img src={removeIcon} alt="delete" onClick={onHandleRemoveFile} />}
											</div>
										)}

										{prevUploadedFile && !deleleFileId && (
											<div className="upload-table__values upload-table__values--uploaded">
												<a href={formik.values.uploadedFile.link} target="_blank" rel="noopener noreferrer">
													{formik.values.uploadedFile.fileName}
												</a>
												{!isDisabled && <img src={removeIcon} alt="delete" onClick={onHandleRemoveFile} />}
											</div>
										)}

										<div className="upload-table__values">{converReadableFileSize(fileSize, true)}</div>
									</div>

									{formik.touched.file && formik.errors.file && <div className="upload-table__footer">{formik.errors.file}</div>}
								</div>
							</div>
						</div>
					</div>

					<div className="campaign__button-container">
						<AppButton type="button" label="Cancel" outline disabled={formik.isSubmitting} onClick={onHandleCancel} />
						<AppButton type="submit" label={submitLabel} disabled={isDisabled} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageCampaign;
