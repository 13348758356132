import ROLES from "common/roles";
import pathnames from "routes/pathnames";
import AppAuditIcon from "components/icons/app-audit-icon";
import AppCampaignIcon from "components/icons/app-campaign-icon";
import AppReferralIcon from "components/icons/app-referral-icon";
import AppReportingIcon from "components/icons/app-reporting-icon";
import AppInsuranceIcon from "components/icons/app-insurance-icon";
import AppDashboardIcon from "components/icons/app-dashboard-icon";
import AppUserManagementIcon from "components/icons/app-user-management-icon";
import AppCustomerSupportIcon from "components/icons/app-customer-support-icon";

const sideNavList = [
	{
		label: "Dashboard",
		path: pathnames.dashboard,
		icon: AppDashboardIcon,
		childrens: [],
	},
	{
		label: "Security",
		path: pathnames.userManagement.customers,
		icon: AppUserManagementIcon,
		names: [ROLES.USER, ROLES.ADMIN, ROLES.ROLE_PRIVILEGES],
		childrens: [
			{
				label: "User Management",
				path: pathnames.userManagement.customers,
				name: ROLES.USER,
				relatives: [pathnames.userManagement.customer],
			},
			{
				label: "Admin Management",
				name: ROLES.ADMIN,
				path: pathnames.userManagement.users,
				relatives: [pathnames.userManagement.user],
			},
			{
				label: "Roles and Privileges",
				name: ROLES.ROLE_PRIVILEGES,
				path: pathnames.userManagement.userRolesPrivileges,
				relatives: [pathnames.userManagement.userRolePrivileges],
			},
		],
	},
	{
		label: "Affiliate Profile\nMaintenance",
		path: pathnames.affiliateProfile.affiliateProfiles,
		icon: AppReferralIcon,
		names: [ROLES.AFFILIATE],
		childrens: [],
		relatives: [pathnames.affiliateProfile.affiliateProfile],
	},
	{
		label: "Insurance Management",
		path: pathnames.insuranceManagement.orders,
		icon: AppInsuranceIcon,
		names: [ROLES.EB_PRODUCTS, ROLES.ENQUIRIES, ROLES.POLICIES, ROLES.CLAIMS, ROLES.AMENDMENT_REQUEST, ROLES.MOTOR],
		childrens: [
			{
				label: "Enquiry",
				path: pathnames.insuranceManagement.enquiries,
				name: ROLES.ENQUIRIES,
				relatives: [],
			},
			{
				label: "Application Listings",
				path: pathnames.insuranceManagement.orders,
				name: ROLES.EB_PRODUCTS,
				relatives: [],
			},
			{
				label: "Policy Listings",
				path: pathnames.insuranceManagement.policies,
				name: ROLES.POLICIES,
				relatives: [],
			},
			{
				label: "Renewal Listings",
				path: pathnames.insuranceManagement.renewals,
				name: ROLES.POLICIES,
				relatives: [],
			},
			{
				label: "Claim Listings",
				path: pathnames.insuranceManagement.claims,
				name: ROLES.CLAIMS,
				relatives: [],
			},
			{
				label: "Amendment Request",
				path: pathnames.insuranceManagement.amendmentRequests,
				name: ROLES.AMENDMENT_REQUEST,
				relatives: [pathnames.insuranceManagement.amendmentRequest],
			},
		],
	},
	{
		label: "Customer Support \nModule",
		path: pathnames.customerSupport.dropUsMessages,
		icon: AppCustomerSupportIcon,
		names: [ROLES.DROP_MESSAGE, ROLES.FAQS],
		childrens: [
			{
				label: "Customer Inquiry",
				path: pathnames.customerSupport.dropUsMessages,
				name: ROLES.DROP_MESSAGE,
				relatives: [],
			},
			{
				label: "FAQ Maintenance",
				path: pathnames.customerSupport.faqs,
				name: ROLES.FAQS,
				relatives: [pathnames.customerSupport.faq],
			},
		],
	},
	{
		label: "Marketing Initiatives",
		path: pathnames.marketingInitiatives.newsAnnouncements,
		icon: AppCampaignIcon,
		names: [ROLES.NEWS_ANNOUNCEMENTS, ROLES.CAMPAIGNS],
		childrens: [
			{
				label: "News & Announcement",
				path: pathnames.marketingInitiatives.newsAnnouncements,
				name: ROLES.NEWS_ANNOUNCEMENTS,
				relatives: [pathnames.marketingInitiatives.newsAnnouncement],
			},
			{
				label: "Advertisement Banner Maintenance",
				path: pathnames.marketingInitiatives.campaigns,
				name: ROLES.CAMPAIGNS,
				relatives: [pathnames.marketingInitiatives.campaign],
			},
		],
	},
	{
		label: "Audit Log",
		path: pathnames.auditLog.payment,
		icon: AppReportingIcon,
		names: [ROLES.ENDORSEMENT_AUDIT_LOG, ROLES.PAYMENT_AUDIT_LOG],
		childrens: [
			{
				label: "Payment Transaction Log",
				path: pathnames.auditLog.payment,
				name: ROLES.PAYMENT_AUDIT_LOG,
				relatives: [],
			},
			{
				label: "EB Endorsement Transaction Log",
				path: pathnames.auditLog.endorsement,
				name: ROLES.ENDORSEMENT_AUDIT_LOG,
				relatives: [],
			},
		],
	},
	{
		label: "Reporting",
		path: pathnames.reporting,
		icon: AppAuditIcon,
		names: [ROLES.AUDIT_REPORT],
		childrens: [],
	},
];

export default sideNavList;
