import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	messages: "",
};

export const versionAlertSlice = createSlice({
	name: "versionAlert",
	initialState,
	reducers: {
		promptVersionAlertMessage: (state, action) => {
			return { message: action.payload?.message };
		},
		dismissVersionAlertMessage: () => initialState,
	},
});

export const { promptVersionAlertMessage, dismissVersionAlertMessage } = versionAlertSlice.actions;

export default versionAlertSlice.reducer;
