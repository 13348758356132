import hero from "assets/images/pages/page-maintenance/hero.png";

const PageMaintenance = () => {
	return (
		<div className="page-maintenance">
			<div className="maintenance">
				<div className="maintenance__hero">
					<img src={hero} alt="maintenance" />
				</div>

				<h1 className="maintenance__title">Oops! Site under maintenance</h1>
				<p className="maintenance__description">we apologize for inconveniences caused and will be back soon.</p>
			</div>
		</div>
	);
};

export default PageMaintenance;
